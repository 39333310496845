var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading-component'),_c('v-main',{staticClass:"page-expirations"},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":""}},[_c('v-container',{staticClass:"container-ship-expirations"},[_vm._l((_vm.expirationsApi),function(expiration,key_expiration){return _c('transition',{key:key_expiration,attrs:{"name":"slideInUp"}},[_c('div',[_c('div',{staticClass:"container-expiration",on:{"click":_vm.show_more}},[_c('div',{staticClass:"expiration-title"},[_c('div',{staticClass:"expiration-mark",class:{
                        hidemark:
                          _vm.expirationCheck(
                            _vm.getOlderDate(expiration.ship_expirations),
                            expiration.expiration_details.reminder
                          ) != 'Caducado',
                      }}),_c('div',{staticClass:"expiration-type"},[_vm._v(" "+_vm._s(expiration.expiration_details.name)+" ")]),_c('div',{class:{
                        expired:
                          _vm.expirationCheck(
                            _vm.getOlderDate(expiration.ship_expirations),
                            expiration.expiration_details.reminder
                          ) == 'Caducado',
                      }},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.getOlderDate(expiration.ship_expirations)))+" ")])]),_c('v-expand-transition',[_c('div',{staticClass:"expiration_details"},[_c('div',{staticClass:"ship_expirations"},_vm._l((Object.keys(
                            expiration.ship_expirations
                          )),function(ship,index_ship){return _c('div',{key:index_ship,staticClass:"ship_expiration",attrs:{"name":"slideInUp"}},[_c('p',[_vm._v(_vm._s(ship))]),_vm._l((Object.values(
                              expiration.ship_expirations[ship]
                            )),function(ship_expiration,ship_expiration_index){return _c('div',{key:ship_expiration_index,staticClass:"expiration-wrapper"},[(ship_expiration.expiration_date !== null)?_c('div',{staticClass:"expirations-info"},[_c('div',{staticClass:"expired_expiration_size",class:{
                                  expired_expiration:
                                    _vm.expirationCheck(
                                      ship_expiration.expiration_date,
                                      expiration.expiration_details.reminder
                                    ) == 'Caducado',
                                }}),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(ship_expiration.identifier_name)+" ")]),(!_vm.expirationsEditable)?_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.formatDate(ship_expiration.expiration_date))+" ")]):_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"type":"date","readonly":""},model:{value:(ship_expiration.expiration_date),callback:function ($$v) {_vm.$set(ship_expiration, "expiration_date", $$v)},expression:"ship_expiration.expiration_date"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es-es"},on:{"input":function($event){return _vm.handleChange(ship_expiration)}},model:{value:(ship_expiration.expiration_date),callback:function ($$v) {_vm.$set(ship_expiration, "expiration_date", $$v)},expression:"ship_expiration.expiration_date"}})],1)],1)]):_vm._e(),(
                                ship_expiration.expiration.expiration_type ===
                                'balsa'
                              )?_c('div',{staticClass:"flex"},[_c('div',{staticClass:"expired_expiration_size",class:{
                                  expired_expiration:
                                    _vm.expirationCheck(
                                      ship_expiration.zafa_expiration_date,
                                      expiration.expiration_details.reminder
                                    ) == 'Caducado',
                                }}),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"text"},[_vm._v("Zafa")]),(!_vm.expirationsEditable)?_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.formatDate( ship_expiration.zafa_expiration_date ))+" ")]):_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"type":"date","readonly":""},model:{value:(ship_expiration.zafa_expiration_date),callback:function ($$v) {_vm.$set(ship_expiration, "zafa_expiration_date", $$v)},expression:"ship_expiration.zafa_expiration_date"}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es-es"},on:{"input":function($event){return _vm.handleChange(ship_expiration)}},model:{value:(ship_expiration.zafa_expiration_date),callback:function ($$v) {_vm.$set(ship_expiration, "zafa_expiration_date", $$v)},expression:"ship_expiration.zafa_expiration_date"}})],1)],1)]):_vm._e(),(
                                ship_expiration_index <
                                expiration.ship_expirations[ship].length - 1
                              )?_c('div',{staticClass:"separation_line"}):_vm._e()])})],2)}),0),(
                          expiration.expiration_details
                            .competent_administration
                        )?_c('div',{staticClass:"ship_organismo"},[_c('p',[_vm._v("Organismo:")]),_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(expiration.expiration_details .competent_administration)+" ")])]):_vm._e()])])],1),(
                    _vm.show_separator(key_expiration)
                  )?_c('div',{staticClass:"separation_line"}):_vm._e()])])}),(Object.keys(_vm.expirationsApi).length <= 0)?_c('v-container',{staticClass:"no_avisos"},[_vm._v(" No tienes caducidades ")]):_vm._e()],2)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 justify-center"},[_vm._v(" ¿Aceptar cambios? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"btn-expiration-edit-save",on:{"click":function($event){return _vm.saveExpirationEdit()}}},[_vm._v(" Si ")]),_c('v-btn',{staticClass:"btn-expiration-edit-cancel",on:{"click":function($event){return _vm.cancelExpirationEdit()}}},[_vm._v(" No ")]),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }