<template>
  <v-main v-if="show" class="page-ships">
    <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <v-flex class="page-ships-panel" xs12 sm10>
          <h3 class="page-ships-text">Selecciona un buque:</h3>
          <transition
            name="slideInUp"
            v-for="(ship, index) in this.ships"
            :key="index"
          >
            <v-card
              v-show="show"
              flat
              class="v-card-blue ship mb-4"
              @click.native="goToVentas(ship.id)"
            >
              <v-card-text>
                <span class="headline">{{ ship.name }}</span>
              </v-card-text>
            </v-card>
          </transition>

        </v-flex>

      </v-layout>
    </v-container>
  </v-main>

</template>

<script>
export default {
  name: 'ventasships',
  components: {},
  data () {
    return {
      show: false,
      title: 'Mis barcos',
      timeout: null
    }
  },
  computed: {
    ships () {
      if (this.$store.state.ships.results !== undefined) {
        return this.$store.state.ships.results
      } else {
        return this.$store.state.ships
      }
    }
  },
  methods: {
    goToVentas (shipId) {
      this.$router.push({
        name: 'ventas',
        params: {
          shipId: shipId
        }
      })
    },
    sortShips (x, y) {
      return x.name.localeCompare(y.name)
    }
  },
  beforeMount () {
    this.$api.ships()
      .then(() => {
        if (!this.ships || this.ships.length === 0) {
          this.$router.push({
            name: 'ventas',
            params: {
              shipId: '0'
            }
          })
        } else if (this.ships.length === 1) {
          this.$router.push({
            name: 'ventas',
            params: {
              shipId: this.ships[0].id
            }
          })
        }
      })
      .catch((error) => {
        if (error.response.status === '403') {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
  },
  mounted () {
    this.show = true
    this.$store.commit('pageTitle', 'Ventas')
    this.$store.commit('pageIcon', 'sales.svg')
  }
}
</script>
