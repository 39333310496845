<template>
  <div id="modules" v-show="show">
    <ul class="modules_list">
      <li v-if="!buyer && !checkHiddenModule('app-ventas') && (entity_type.includes('v') || entity_type.includes('cv') )">
        <div
          v-if="checkModule('app-ventas')"
          @click="moduleClick('/ventas')"
        >
          <img
            src="../assets/i/icons/sales.svg"
            alt="Ventas"
            class="icon-ventas"
          />
          <span>VENTAS</span>
        </div>
        <div v-else class="module-off">
            <img
              src="../assets/i/icons/sales.svg"
              alt="Ventas"
              class="icon-ventas"
            />
          <span>VENTAS</span>
        </div>
      </li>
      <li v-if="!checkHiddenModule('app-compras') && (entity_type.includes('c') || entity_type.includes('cv') )">
        <div v-if="checkModule('app-compras')" @click="moduleClick('/compras')">
          <img
            src="../assets/i/icons/sales.svg"
            alt="Compras"
            class="icon-compras"
          />
          <span>COMPRAS</span>
        </div>
        <div v-else class="module-off">
          <img
            src="../assets/i/icons/sales.svg"
            alt="Compras"
            class="icon-compras"
          />
          <span>COMPRAS</span>
        </div>
      </li>
      <li v-if="!checkHiddenModule('app-subasta')">
        <div
          v-if="checkModule('app-subasta')"
          @click="moduleClick('/subasta')"
        >
          <img
            src="../assets/i/icons/auctions.svg"
            alt="Subasta"
            class="icon-subasta"
          />
          <span>SUBASTA</span>
        </div>
        <div v-else class="module-off">
          <img
            src="../assets/i/icons/auctions.svg"
            alt="Subasta"
            class="icon-subasta"
          />
          <span>SUBASTA</span>
        </div>
      </li>
      <li v-if="!buyer">
        <div @click="moduleClick('/barco')">
          <img
            src="../assets/i/icons/quotas.svg"
            alt="Cuotas"
            class="icon-cuotas"
          />
          <span>CUOTAS</span>
        </div>
      </li>
      <li v-if="!buyer && !checkHiddenModule('app-esfuerzos')">
        <div v-if="checkModule('app-esfuerzos')" @click="moduleClick('/barcoEsf')">
          <img
            src="../assets/i/icons/efforts.svg"
            alt="Esfuerzos"
            class="icon-efforts"
            />
            <span>ESFUERZOS</span>
        </div>
        <div v-else class="module-off">
          <img
          src="../assets/i/icons/efforts.svg"
          alt="Esfuerzos"
          class="icon-efforts"
          />
          <span>ESFUERZOS</span>
        </div>
      </li>
      <li v-if="!buyer && !checkHiddenModule('app-capturas')">
        <div
          v-if="checkModule('app-capturas')"
          @click="moduleClick('/captures')"
        >
          <img
            src="../assets/i/icons/capturas.svg"
            alt="Capturas"
            class="icon-capturas"
          />
          <span>CAPTURAS</span>
        </div>
        <div v-else class="module-off">
          <img
            src="../assets/i/icons/capturas.svg"
            alt="Capturas"
            class="icon-capturas"
          />
          <span>CAPTURAS</span>
        </div>
      </li>
      <li v-if="!buyer && !checkHiddenModule('app-mapas')">
        <div
          v-if="checkModule('app-mapas')"
          @click="moduleClick('/captures-map')"
        >
          <img
            src="../assets/i/icons/mapas.svg"
            alt="Mapas"
            class="icon-mapas"
          />
          <span>MAPAS</span>
        </div>
        <div v-else class="module-off">
          <img
            src="../assets/i/icons/mapas.svg"
            alt="Mapas"
            class="icon-mapas"
          />
          <span>MAPAS</span>
        </div>
      </li>
      <li v-if="!checkHiddenModule('app-species')">
        <div v-if="checkModule('app-species')" @click="moduleClick('/species')">
          <img
            src="../assets/i/icons/especies.svg"
            alt="Especies"
            class="icon-especies"
          />
          <span>ESPECIES</span>
        </div>
        <div v-else class="module-off">
          <img
            src="../assets/i/icons/especies.svg"
            alt="Especies"
            class="icon-especies"
          />
          <span>ESPECIES</span>
        </div>
      </li>
      <li v-if="!checkHiddenModule('app-tallas')">
        <div v-if="checkModule('app-tallas')" @click="moduleClick('/tallas')">
          <img
            src="../assets/i/icons/tallas.svg"
            alt="Tallas"
            class="icon-tallas"
          />
          <span>TALLAS</span>
        </div>
        <div v-else class="module-off">
          <img
            src="../assets/i/icons/tallas.svg"
            alt="Tallas"
            class="icon-tallas"
          />
          <span>TALLAS</span>
        </div>
      </li>
      <li v-if="!buyer && !checkHiddenModule('app-avisos')">
        <div v-if="checkModule('app-avisos')" @click="moduleClick('/avisos')">
          <v-container class="container_img">
            <div v-if="unread_avisos > 0" class="red_circle">
              {{ unread_avisos }}
            </div>
            <img
              src="../assets/i/icons/avisos.svg"
              alt="Avisos"
              class="icon-avisos"
            />
          </v-container>
          <span>AVISOS</span>
        </div>
        <div v-else class="module-off">
          <img
            src="../assets/i/icons/avisos.svg"
            alt="Avisos"
            class="icon-avisos"
          />
          <span>AVISOS</span>
        </div>
      </li>
      <li v-if="!checkHiddenModule('app-seatides')">
        <div v-if="checkModule('app-seatides')" @click="moduleClick('/seatides')">
          <img
            src="../assets/i/icons/seatides.svg"
            alt="Seatides"
            class="icon-seatides"
          />
          <span>MAREAS</span>
        </div>
        <div v-else class="module-off">
          <img
            src="../assets/i/icons/seatides.svg"
            alt="Seatides"
            class="icon-seatides"
          />
          <span>MAREAS</span>
        </div>
      </li>
      <li v-if="!buyer && !checkHiddenModule('app-caducidades')">
        <div v-if="checkModule('app-caducidades')" @click="moduleClick('/caducidades')">
          <v-container class="container_img">
            <div v-if="expired_expirations > 0" class="red_circle">
              {{ expired_expirations }}
            </div>
            <img
              src="../assets/i/icons/expirations.svg"
              alt="Caducidades"
              class="icon-caducidades"
            />
          </v-container>
          <span>CADUCIDADES</span>
        </div>
        <div v-else class="module-off">
          <img
            src="../assets/i/icons/expirations.svg"
            alt="Caducidades"
            class="icon-caducidades"
          />
          <span>CADUCIDADES</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
global.jQuery = require('jquery')
var $ = global.jQuery
window.$ = $

export default {
  name: 'modulesComponent',
  data () {
    return {
      show: false,
      buyer: false
    }
  },
  computed: {
    modules () {
      return this.$store.state.modules
    },
    hidden_modules () {
      return this.$store.state.hidden_modules
    },
    unread_avisos () {
      if (this.checkModule('app-avisos') && this.$store.state.avisos.unread_notifications !== undefined) {
        if (this.$store.state.avisos.unread_notifications < 100) {
          return this.$store.state.avisos.unread_notifications
        } else {
          return '+99'
        }
      } else {
        return 0
      }
    },
    expired_expirations () {
      if (
        this.$store.state.expirations !== undefined &&
        this.checkModule('app-caducidades')
      ) {
        let expiredExpirations = 0
        for (let index = 0; index < this.$store.state.expirations.length; index++) {
          let expiration = this.$store.state.expirations[index]
          if (expiration.expiration_date &&
              this.expirationCheck(expiration.expiration_date) === 'Caducado') {
            expiredExpirations++
          }
          if (expiration.expiration.expiration_type === 'balsa' &&
              expiration.zafa_expiration_date &&
              this.expirationCheck(expiration.zafa_expiration_date) === 'Caducado') {
            expiredExpirations++
          }
        }
        if (expiredExpirations < 100) {
          return expiredExpirations
        } else {
          return '+99'
        }
      } else {
        return 0
      }
    },
    entity_type () {
      return this.$store.state.entity_type
    }
  },
  methods: {
    checkHiddenModule (moduleName) {
      const index = this.hidden_modules.map((e) => e.name).indexOf(moduleName)
      return index > -1
    },
    checkModule (moduleName) {
      const index = this.modules.map((e) => e.name).indexOf(moduleName)
      return index > -1
    },
    moduleClick (moduleRoute) {
      if (this.$route.path === moduleRoute) {
        this.$store.commit('drawer', !this.$store.state.drawer)
      } else {
        if (moduleRoute === '/compras') {
          this.goToCompras()
        } else {
          this.$router.push(moduleRoute)
        }
        var pageName = null
        switch (this.$route.name) {
          case 'ships':
            pageName = 'cuotas'
            break

          case 'captures':
            pageName = 'capturas'
            break

          case 'captures-map':
            pageName = 'mapas'
            break

          case 'species':
            pageName = 'especies'
            break

          default:
            pageName = this.$route.name
            break
        }

        this.$gtag.pageview('/' + pageName)
        this.$gtag.event('modulo_' + pageName, {
          'event_category': 'acceso',
          'event_label': 'label',
          'value': 1
        })
      }
      if (this.checkModule('app-avisos')) {
        if (
          new Date().getTime() - this.$store.state.lastNotificationsUpdate >
          process.env.VUE_APP_NOTIFICATIONS_CACHE_TIME * 1000
        ) {
          this.$api
            .avisos()
            .then((response) => {
              this.$store.commit('lastNotificationsUpdate', new Date().getTime())
            })
            .catch((error) => {
              if (['403', '404'].indexOf(error.response.status) > -1) {
                this.$store.commit('token', null)
                this.$router.push('/login')
              }
            })
        }
      }
    },
    goToCompras () {
      if (
        this.$store.state.ports_remote_control_buyer_purchases.length <= 1 &&
        this.$store.state.ports_remote_control_buyer_purchases[0].alias.length <= 1
      ) {
        this.$router.push({
          name: 'compras',
          params: {
            portId: 0,
            aliasId: 0
          }
        })
      } else {
        this.$router.push({
          name: 'comprasAlias'
        })
      }
    },
    assignStructureModules () {
      let modulesList = document.getElementsByClassName('modules_list')
      let modulesLength = modulesList[0].getElementsByTagName('li').length
      if (modulesLength > 6 && modulesLength < 13) {
        modulesList.forEach((ul) => ul.classList.add('modulesSevenToTwelve'))
      }
    },
    expirationCheck (date) {
      var expirationYear = date.slice(0, 4)
      var expirationMonth = date.slice(5, 7)
      var expirationDay = date.slice(8, 10)
      var currentYearTime = new Date()
      var currentDate = new Date(
        currentYearTime.getFullYear(),
        currentYearTime.getMonth(),
        currentYearTime.getDate()
      ).getTime()
      var dateExpiration = new Date(
        expirationYear,
        expirationMonth - 1,
        expirationDay
      ).getTime()
      if (dateExpiration - currentDate <= 0) {
        return 'Caducado'
      } else if (dateExpiration - currentDate <= 1000 * 60 * 60 * 24 * 30) {
        return 'A punto de caducar'
      } else {
        return 'Vigente'
      }
    }
  },
  created () {
    if (this.$store.getters.authenticated) {
      this.$api.modules().then((response) => {
        if (response.data.ship_owner.buyer) {
          this.buyer = true
        }
        if (this.checkModule('app-avisos')) {
          this.$api
            .avisos()
            .then((response) => {
              this.unread_avisos_data = response.data.unread_notifications
            })
            .catch((error) => {
              if (error.response.status === 403) {
                this.$store.commit('token', null)
                this.$router.push('/login')
              }
            })
        }
        if (this.checkModule('app-caducidades')) {
          this.$api.expirations().catch((error) => {
            if (error.response.status === 403) {
              this.$store.commit('token', null)
              this.$router.push('/login')
            }
          })
        }
      })
      this.$api.entity_type().then((response) => {
        if (response.data.entity_type.includes('c') || response.data.entity_type.includes('cv') ) {
          this.$api
            .ports_remote_control_buyer_purchases()
            .then(() => {
            })
            .catch((error) => {
              if (error.response.status === 403) {
                this.$store.commit('token', null)
                this.$router.push('/login')
              }
            })
        }
      })
    }
  },
  mounted () {
    this.$api.modules().then((response) => {
      if (response.data.ship_owner.buyer) {
        this.buyer = true
      }
      this.assignStructureModules()
      this.show = true
    })
  }
}
</script>
