import Vue from 'vue'
import Router from 'vue-router'
import Vuex from 'vuex'
import store from './store'
import Ships from './views/Ships.vue'
import LoginRequest from './views/LoginRequest.vue'
import LoginPin from './views/LoginPin.vue'
import Menu from './views/Menu.vue'
import Quota from './views/Quota.vue'
import Stocks from './views/Stocks.vue'
import CapturesView from './views/CapturesView.vue'
import CapturesMap from './views/CapturesMap.vue'
import SpeciesView from './views/SpeciesView.vue'
import SpecieDetailView from './views/SpecieDetailView.vue'
import TallasView from './views/TallasView.vue'
import AvisosView from './views/AvisosView.vue'
import AvisoDetailView from './views/AvisoDetailView.vue'
import SeatidesView from './views/SeatidesView.vue'
import ExpirationsView from './views/ExpirationsView.vue'
import VentasShipsView from './views/VentasShipsView.vue'
import VentasView from './views/VentasView.vue'
import ComprasView from './views/ComprasView.vue'
import ComprasAliasView from './views/ComprasAliasView.vue'
import SubastaPortsView from './views/SubastaPortsView.vue'
import SubastaView from './views/SubastaView.vue'
import ShipsEffort from './views/ShipsEffort.vue'
import Effort from './views/Effort.vue'

Vue.use(Vuex)
Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginRequest
    },
    {
      path: '/pin',
      name: 'login-pin',
      props: true,
      component: LoginPin
    },
    {
      path: '/',
      name: 'menu',
      component: Menu,
      meta: {
        auth: true
      }
    },
    {
      path: '/barco/',
      name: 'ships',
      props: true,
      component: Ships,
      meta: {
        auth: true
      }
    },
    {
      path: '/barco/',
      name: 'stocks',
      props: true,
      component: Stocks,
      meta: {
        auth: true
      }
    },
    {
      path: '/barco/quota/',
      name: 'quota',
      props: true,
      component: Quota,
      meta: {
        auth: true
      }
    },
    {
      path: '/barcoEsf/',
      name: 'shipsEffort',
      props: true,
      component: ShipsEffort,
      meta: {
        auth: true
      }
    },
    {
      path: '/barcoEsf/effort/',
      name: 'effort',
      props: true,
      component: Effort,
      meta: {
        auth: true
      }
    },
    {
      path: '/captures/',
      name: 'captures',
      component: CapturesView,
      meta: {
        auth: true
      }
    },
    {
      path: '/captures-map/',
      name: 'captures-map',
      component: CapturesMap,
      meta: {
        auth: true
      }
    },
    {
      path: '/species/',
      name: 'species',
      component: SpeciesView,
      meta: {
        auth: true
      }
    },
    {
      path: '/species/:id',
      name: 'specie',
      props: true,
      component: SpecieDetailView,
      meta: {
        auth: true
      }
    },
    {
      path: '/tallas/',
      name: 'tallas',
      component: TallasView,
      meta: {
        auth: true
      }
    },
    {
      path: '/avisos/',
      name: 'avisos',
      component: AvisosView,
      meta: {
        auth: true
      }
    },
    {
      path: '/avisos/:id',
      name: 'aviso',
      props: true,
      component: AvisoDetailView,
      meta: {
        auth: true
      }
    },
    {
      path: '/seatides/',
      name: 'seatides',
      component: SeatidesView,
      meta: {
        auth: true
      }
    },
    {
      path: '/caducidades/',
      name: 'caducidades',
      component: ExpirationsView,
      meta: {
        auth: true
      }
    },
    {
      path: '/ventas/',
      name: 'ventasShips',
      component: VentasShipsView,
      meta: {
        auth: true
      }
    },
    {
      path: '/ventas/',
      name: 'ventas',
      props: true,
      component: VentasView,
      meta: {
        auth: true
      }
    },
    {
      path: '/compras/',
      name: 'comprasAlias',
      props: true,
      component: ComprasAliasView,
      meta: {
        auth: true
      }
    },
    {
      path: '/compras/port/alias',
      name: 'compras',
      props: true,
      component: ComprasView,
      meta: {
        auth: true
      }
    },
    {
      path: '/subasta/',
      name: 'subastaPorts',
      component: SubastaPortsView,
      meta: {
        auth: true
      }
    },
    {
      path: '/subasta/',
      name: 'subasta',
      props: true,
      component: SubastaView,
      meta: {
        auth: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (!store.getters.authenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
