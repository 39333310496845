<template>
  <transition name="fade">
    <div v-if="show" id="loading">
      <div class="content">
        <div class="logo-wrapper">
          <img src="../assets/i/deo_no_o.png" alt="DEO" class="logo"/>
          <img src="../assets/i/spinner.png" alt="DEO" class="spinner"/>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'loading-component',
  computed: {
    show () {
      return this.$store.state.loading
    }
  }
}
</script>
