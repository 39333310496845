<script>
import { Bar } from 'vue-chartjs'

export default {
  name: 'specie-graph',
  extends: Bar,
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    groupBy (arr, property) {
      return arr.reduce(function (memo, x) {
        if (!memo) {
          memo = []
        }
        if (property === 'month') {
          switch (x[property]) {
            case '01':
              memo.push('E')
              break
            case '02':
              memo.push('F')
              break
            case '03':
              memo.push('M')
              break
            case '04':
              memo.push('A')
              break
            case '05':
              memo.push('M')
              break
            case '06':
              memo.push('J')
              break
            case '07':
              memo.push('J')
              break
            case '08':
              memo.push('A')
              break
            case '09':
              memo.push('S')
              break
            case '10':
              memo.push('O')
              break
            case '11':
              memo.push('N')
              break
            case '12':
              memo.push('D')
              break
            default:
              memo.push('NaN')
              break
          }
        } else {
          memo.push(x[property])
        }
        return memo
      }, [])
    },
    prepareChart () {
      // Overwriting base render method with actual data.
      this.renderChart(
        {
          labels: this.groupBy(this.data, 'month'),
          datasets: [
            {
              label: 'Capturas',
              backgroundColor: '#37A3DC',
              data: this.groupBy(this.data, 'sum')
            }
          ]
        },
        {
          legend: {
            display: false
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: false
                  // color: 'white'
                },
                ticks: {
                  fontColor: 'white',
                  fontStyle: 'bold'
                }
              }
            ],
            yAxes: [
              {
                display: false,
                gridLines: {
                  display: false,
                  color: 'grey'
                },
                ticks: {
                  fontColor: 'white',
                  fontStyle: 'bold'
                }
              }
            ]
          }
        }
      )
    }
  },
  watch: {
    data: function (val) {
      this.prepareChart()
    }
  },
  mounted () {
    this.prepareChart()
  }
}
</script>
