<template>
  <v-main class="page-species">
    <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <v-flex class="page-species-panel" xs12 sm10>
          <div class="div-search">
            <v-text-field
              class="textfield-search"
              append-icon="search"
              label="especie"
              single-line
              hide-details
              v-model="search"
              solo
            ></v-text-field>
          </div>
          <v-tabs v-model="currentTable">
            <v-tab :href="'#tab-todas'" class="v-tab-todas"> Todas </v-tab>
            <v-tab :href="'#tab-prohibidas'" class="v-tab-prohibidas">
              Prohibidas
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="currentTable">
            <v-tab-item :value="'tab-todas'">
              <v-card flat height="75vh">
                <v-card-text>
                  <table>
                    <tr
                      class="page-species-row-item"
                      v-for="(specie, index) in species"
                      :key="index"
                      @click="detail(specie.id)"
                    >
                      <td>
                        <tr>
                          <td class="page-species-row-item-name">
                            {{ specie.spanish_name }}
                            <p class="page-species-row-item-scientific">
                              {{ specie.scientific_name }}
                            </p>
                          </td>
                          <td class="page-species-row-item-img">
                            <img
                              v-if="specie.img != null"
                              :src="get_img_url(specie.img)"
                              :alt="specie.spanish_name"
                            />
                          </td>
                        </tr>
                        <tr v-if="specie.notes != ''">
                          <td>
                            <div class="except-tag">
                              Excepciones
                            </div>
                          </td>
                        </tr>
                        <tr v-if="specie.forbidden">
                          <td>
                            <div class="forbidden-tag">
                              Prohibida
                            </div>
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item :value="'tab-prohibidas'">
              <v-card flat height="75vh">
                <v-card-text>
                  <table>
                    <tr
                      class="page-species-row-item"
                      v-for="(specie, index) in species.filter(
                        (specie) => specie.forbidden == true
                      )"
                      :key="index"
                      @click="detail(specie.id)"
                    >
                      <td>
                        <tr>
                          <td class="page-species-row-item-name">
                            {{ specie.spanish_name }}
                            <p class="page-species-row-item-scientific">
                              {{ specie.scientific_name }}
                            </p>
                          </td>
                          <td class="page-species-row-item-img">
                            <img
                              v-if="specie.img != null"
                              :src="get_img_url(specie.img)"
                              :alt="specie.spanish_name"
                            />
                          </td>
                        </tr>
                        <tr v-if="specie.notes != ''">
                          <td>
                            <div class="except-tag">
                              Excepciones
                            </div>
                          </td>
                        </tr>
                        <tr v-if="specie.forbidden">
                          <td>
                            <div class="forbidden-tag">
                              Prohibida
                            </div>
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'species',
  data () {
    return {
      currentTable: 'tab-todas',
      search: '',
      especies_data: [],
      searchedData: [],
      searchFlag: false
    }
  },
  watch: {
    search () {
      this.searchDetail()
    }
  },
  computed: {
    species () {
      if (this.searchFlag) {
        return this.searchedData
      } else {
        return this.species_total
      }
    },
    species_total () {
      if (this.$store.state.species.length !== undefined) {
        return this.$store.state.species
      } else {
        return this.especies_data
      }
    }
  },
  methods: {
    searchDetail () {
      this.searchedData = []
      this.searchFlag = false
      if (this.search !== '') {
        this.searchFlag = true
        for (let i in this.species_total) {
          if (
            this.species_total[i].spanish_name.toUpperCase().includes(this.search.toUpperCase())
          ) {
            this.searchedData.push(this.species_total[i])
          }
        }
      }
    },
    detail (id) {
      this.$router.push({
        name: 'specie',
        params: {
          id
        }
      })
    },
    get_img_url (url) {
      if (url.substr(0, 5) !== 'https' && url.substr(0, 4) === 'http') {
        let urlFixed = 'https' + url.substr(4)
        return urlFixed
      } else {
        return url
      }
    }
  },
  mounted () {
    this.$api
      .species()
      .then((response) => {
        this.especies_data = response.data.results
      })
      .catch((error) => {
        if (error.response.status === '403') {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
    this.$store.commit('pageTitle', 'Especies')
    this.$store.commit('pageIcon', 'especies.svg')
  }
}
</script>
