<template>
  <v-main class="page-quota-effort">
    <v-container fluid>
      <v-layout justify-center>
        <v-flex xs12>
            <div v-if="quotas_sales && cuotasVentas" class="quota-sales-main">
              <div class="open header">
                <h2>{{ quotas_sales.ship.name }}</h2>
                <h3>
                  {{ quotas_sales.quota__stock__name }}
                </h3>
              </div>
              <div class="info-quota-sales">
                <span>Total: {{ quotas_sales.quota_total }} Kg</span>
                <span>Consumida: {{ quotas_sales.consumed }} Kg</span>
                <span>Restante: {{ quotas_sales.pending_quota }} Kg</span>
              </div>
              <div class="quota-detail-data-graph">
                <v-progress-circular
                          :size="250"
                          :width="30"
                          :rotate="-90"
                          color="#15BDE6"
                          v-model="capturedPercent"
                          style="margin-bottom: 15px"
                          >
                          <span class="card-text">
                            {{ capturedPercent }}%
                          </span>
                        </v-progress-circular>
                </div>
            </div>
            <div v-else-if="gestcuota == 1 && gestcuota_all_years[0]">
              <div class="open header">
                <h2>{{ ship.name }}</h2>
                <h3>
                  {{ quota.stock.name }}
                </h3>
              </div>
              <v-tabs v-model="currentTab">
                <v-tab v-for="(gestcuota_item, index) in gestcuota_all_years" :key="index" :href="'#tab-' + gestcuota_item.campaign_year" class="v-tab">
                  {{ gestcuota_item.campaign_year }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="currentTab">
                <v-tab-item v-for="(gestcuota_item, index) in gestcuota_all_years" :key="index" :value="'tab-' + gestcuota_item.campaign_year">
                  <v-card v-if="gestcuota_item.campaign_year == currentYear" class="open detail" :class="{noStockGraph: gestcuota_item.consumed_by_month.length == 0}">
                    <v-card-text>
                      <div class="quota-detail-data-text">
                        <v-layout>
                          <v-flex xs12>
                            <label v-if="quota.real_amount > 0" class="card-text">
                              Total: {{ quota.real_amount | formatNumber }} Kg
                            </label>
                            <label v-else class="card-text">
                              Total: Pendiente de asignar
                            </label>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex xs12>
                            <label class="card-text">
                              Consumida: {{ quota.consumed | formatNumber }} Kg
                            </label>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex xs12 align-items-right>
                            <label v-if="quota.real_amount > 0" class="card-text">
                              Restante:
                              {{ (quota.real_amount - quota.consumed) | formatNumber }}
                              Kg
                            </label>
                            <label v-else class="card-text">
                              Restante: -
                            </label>
                          </v-flex>
                        </v-layout>
                      </div>
                      <div class="quota-detail-data-graph">
                        <v-layout>
                          <v-flex
                            xs12
                            align-items-center
                            class="page-quota-panel-circle"
                          >
                            <v-progress-circular
                              :size="130"
                              :width="18"
                              :rotate="-90"
                              color="#15BDE6"
                              v-model="capturedPercent"
                              style="margin-bottom: 15px"
                            >
                              <span class="card-text">
                                {{ capturedPercent }}%
                              </span>
                            </v-progress-circular>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-card-text>
                    <stock-graph v-if="gestcuota_item.consumed_by_month" :data="gestcuota_item.consumed_by_month"></stock-graph>
                  </v-card>
                  <v-card v-else class="open detail" :class="{noStockGraph: gestcuota_item.consumed_by_month.length == 0}">
                    <v-card-text>
                      <div class="quota-detail-data-text">
                        <v-layout>
                          <v-flex xs12>
                            <label v-if="gestcuota_item.monthData[0].adapted_amount > 0" class="card-text">
                              Total: {{ gestcuota_item.monthData[0].adapted_amount | formatNumber }} Kg
                            </label>
                            <label v-else class="card-text">
                              Total: Pendiente de asignar
                            </label>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex xs12>
                            <label class="card-text">
                              Consumida: {{ gestcuota_item.monthData[0].consumed | formatNumber }} Kg
                            </label>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex xs12 align-items-right>
                            <label v-if="gestcuota_item.monthData[0].adapted_amount > 0" class="card-text">
                              Restante:
                              {{ (gestcuota_item.monthData[0].adapted_amount - gestcuota_item.monthData[0].consumed) | formatNumber }}
                              Kg
                            </label>
                            <label v-else class="card-text">
                              Restante: -
                            </label>
                          </v-flex>
                        </v-layout>
                      </div>
                      <div class="quota-detail-data-graph">
                        <v-layout>
                          <v-flex
                            xs12
                            align-items-center
                            class="page-quota-panel-circle"
                          >
                            <!-- <v-progress-circular
                              :size="130"
                              :width="18"
                              :rotate="-90"
                              color="#15BDE6"
                              v-model="cp_gestcuota"
                              style="margin-bottom: 15px"
                            > -->

                            <v-progress-circular
                              :size="130"
                              :width="18"
                              :rotate="-90"
                              color="#15BDE6"
                              v-model="gestcuota_item.percentage"
                              style="margin-bottom: 15px"
                            >
                              <span class="card-text">
                                {{ gestcuota_item.percentage }}%
                              </span>
                            </v-progress-circular>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-card-text>
                    <stock-graph v-if="gestcuota_item.consumed_by_month" :data="gestcuota_item.consumed_by_month"></stock-graph>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </div>
            <div v-else>
              <div v-if="gestcuota == 1" class="open header">
                <h2>{{ ship.name }}</h2>
                <h3>
                  {{ quota.stock.name }}
                </h3>
              </div>
              <v-card v-if="gestcuota == 1" class="open detail" :class="{noStockGraph: consumed_by_month.length == 0}">
                <v-card-text>
                  <div class="quota-detail-data-text">
                    <v-layout>
                      <v-flex xs12>
                        <label v-if="quota.real_amount > 0" class="card-text">
                          Total: {{ quota.real_amount | formatNumber }} Kg
                        </label>
                        <label v-else class="card-text">
                          Total: Pendiente de asignar
                        </label>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex xs12>
                        <label class="card-text">
                          Consumida: {{ quota.consumed | formatNumber }} Kg
                        </label>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex xs12 align-items-right>
                        <label v-if="quota.real_amount > 0" class="card-text">
                          Restante:
                          {{ (quota.real_amount - quota.consumed) | formatNumber }}
                          Kg
                        </label>
                        <label v-else class="card-text">
                          Restante: -
                        </label>
                      </v-flex>
                    </v-layout>
                  </div>
                  <div class="quota-detail-data-graph">
                    <v-layout>
                      <v-flex
                        xs12
                        align-items-center
                        class="page-quota-panel-circle"
                      >
                        <v-progress-circular
                          :size="130"
                          :width="18"
                          :rotate="-90"
                          color="#15BDE6"
                          v-model="capturedPercent"
                          style="margin-bottom: 15px"
                        >
                          <span class="card-text">
                            {{ capturedPercent }}%
                          </span>
                        </v-progress-circular>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-card-text>
                <stock-graph v-if="consumed_by_month" :data="consumed_by_month"></stock-graph>
              </v-card>
              <div v-else>
                <div class="open header">
                  <h2>{{ ship.name }}</h2>
                  <h3>
                    {{ quota.stock.name }}
                  </h3>
                </div>
                <v-card class="open detail">
                  <v-card-text>
                    <div class="quota-detail-data-text">
                      <v-layout>
                        <v-flex xs12>
                          <label v-if="quota.real_amount > 0" class="card-text">
                            Total: {{ quota.real_amount | formatNumber }} Kg
                          </label>
                          <label v-else class="card-text">
                            Total: Pendiente de asignar
                          </label>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex xs12>
                          <label class="card-text">
                            Consumida: {{ quota.consumed | formatNumber }} Kg
                          </label>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex xs12 align-items-right>
                          <label v-if="quota.real_amount > 0" class="card-text">
                            Restante:
                            {{ (quota.real_amount - quota.consumed) | formatNumber }}
                            Kg
                          </label>
                          <label v-else class="card-text">
                            Restante: -
                          </label>
                        </v-flex>
                      </v-layout>
                    </div>
                    <div class="quota-detail-data-graph">
                      <v-layout>
                        <v-flex
                          xs12
                          align-items-center
                          class="page-quota-panel-circle"
                        >
                          <v-progress-circular
                            :size="130"
                            :width="18"
                            :rotate="-90"
                            color="#15BDE6"
                            v-model="capturedPercent"
                            style="margin-bottom: 15px"
                          >
                            <span class="card-text">
                              {{ capturedPercent }}%
                            </span>
                          </v-progress-circular>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card-text>
                  <stock-graph v-if="consumed_by_month" :data="consumed_by_month"></stock-graph>
                </v-card>
              </div>
            </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import StockGraph from '../components/StockGraph'

export default {
  name: 'stock',
  components: {
    'stock-graph': StockGraph
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    id_gestcuota: {
      type: [Number, String],
      required: true
    },
    shipId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      show: false,
      currentTab: '',
      consumed_by_month: [],
      cp_gestcuota: null,
      gestcuota_all_years: [],
      quotas_sales: null
    }
  },
  computed: {
    gestcuota () {
      const index = this.$store.state.modules.map((e) => e.name).indexOf('gestcuotas')
      return index > -1
    },
    cuotasVentas () {
      const index = this.$store.state.modules.map((e) => e.name).indexOf('app-cuotas-ventas')
      return index > -1
    },
    ship () {
      const ships = this.$store.state.ships.results
      for (let i in ships) {
        if (parseInt(ships[i].id) === parseInt(this.shipId)) {
          return ships[i]
        }
      }
      return null
    },
    quota () {
      const quotas = this.$store.state.quotas
      if (!this.gestcuota | this.id_gestcuota === 'none') {
        for (let q in quotas) {
          if (quotas[q].id === this.id) {
            return quotas[q]
          }
        }
      } else {
        for (let q in quotas) {
          if (quotas[q].id_gestcuota === this.id_gestcuota) {
            return quotas[q]
          }
        }
      }
      return null
    },
    remainPercent () {
      if (this.quotas_sales && this.cuotasVentas) {
        if (this.quotas_sales.quota_total > 0) {
          return (
            ((this.quotas_sales.quota_total - this.quotas_sales.consumed) /
              this.quotas_sales.quota_total) *
            100
          ).toFixed(2)
        } else {
          return (0).toFixed(2)
        }
      } else {
        if (this.quota.real_amount > 0) {
          return (
            ((this.quota.real_amount - this.quota.consumed) /
              this.quota.real_amount) *
            100
          ).toFixed(2)
        } else {
          return (0).toFixed(2)
        }
      }
    },
    capturedPercent () {
      if (isNaN(this.remainPercent)) {
        return 0
      } else if (this.remainPercent <= 0) {
        return (0).toFixed(2)
      } else {
        return (100 - this.remainPercent).toFixed(2)
      }
    },
    currentYear () {
      return new Date().getFullYear()
    }
  },
  methods: {
    pad (n, width, z) {
      z = z || '0'
      n = n + ''
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join(z) + n
    },
    formatStockGraphData (year, cbmInput, cbmOutput) {
      for (let m = 1; m <= 12; m++) {
        let month = `${year}-${this.pad(m, 2)}`
        let monthSimple = this.pad(m, 2)
        let sum = 0
        cbmInput.forEach((item) => {
          if (item.month === month) {
            sum += parseFloat(item.sum)
          }
        })
        sum = sum.toFixed(2)
        cbmOutput.push({
          month: monthSimple,
          sum
        })
      }
    },
    orderGestcuotasAllYears (x, y) {
      return String(x.campaign_year).localeCompare(String(y.campaign_year))
    },
    remainPercent_gestcuota (data) {
      if (data.adapted_amount > 0) {
        return (
          ((data.adapted_amount - data.consumed) /
            data.adapted_amount) *
          100
        ).toFixed(2)
      } else {
        return (0).toFixed(2)
      }
    },
    capturedPercent_gestcuota (data) {
      if (isNaN(this.remainPercent_gestcuota(data))) {
        return 0
      } else {
        return (100 - this.remainPercent_gestcuota(data)).toFixed(2)
      }
    }
  },
  beforeMount () {
    if (this.cuotasVentas) {
      this.quotas_sales = this.$store.state.quotas_sales.filter((item) => item.quota_id === this.id)[0]
    }
    if (this.gestcuota) {
      this.$api.gestcuota_all_years(this.quota.ships[0].id, this.quota.stock.id)
        .then((response) => {
          let gestcuotaAy = response.data.results.sort(this.orderGestcuotasAllYears)
          gestcuotaAy.forEach((gest) => {
            gest.consumed_by_month = []
            gest.percentage = this.capturedPercent_gestcuota(gest.monthData[0])
          })
          this.currentTab = 'tab-' + this.currentYear
          this.$api.quota_all_years(this.quota.ships[0].id, this.quota.stock.id)
            .then((response) => {
              if (response.data.quotas[0]) {
                response.data.quotas.forEach((item) => {
                  gestcuotaAy.forEach((gest) => {
                    if (
                      String(gest.ship.id) === String(item.ships[0].id) &&
                      String(gest.stock.id) === String(item.stock.id) &&
                      String(gest.campaign_year) === String(item.year)
                    ) {
                      let year = item.year
                      let consumedByMonth = []
                      if (item.consumed_by_month.length > 0) {
                        this.formatStockGraphData(year, item.consumed_by_month, consumedByMonth)
                      }
                      gest.consumed_by_month = consumedByMonth
                    }
                  })
                  this.gestcuota_all_years = gestcuotaAy
                })
              } else {
                this.gestcuota_all_years = gestcuotaAy
              }
            })
            .catch((error) => {
              if (error.response.status === '403') {
                this.$store.commit('token', null)
                this.$router.push('/login')
              }
            })
        })
        .catch((error) => {
          if (error.response.status === '403') {
            this.$store.commit('token', null)
            this.$router.push('/login')
          }
        })
    }
    this.formatStockGraphData(this.currentYear, this.quota.consumed_by_month, this.consumed_by_month)
  },
  mounted () {
    this.show = true
    this.$store.commit('pageTitle', 'Cuotas')
    this.$store.commit('pageIcon', 'quotas.svg')
  }
}
</script>
