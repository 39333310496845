<template>
  <div>
    <loading-component></loading-component>
    <v-main class="page-compras">
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-flex xs12 sm10>
            <v-container class="container-compras">
              <div class="botones-fechas">
                <button v-on:click="load_auctions_rows(-1)">
                  <img
                    src="../assets/i/icons/arrow_down.svg"
                    class="icon-row-left"
                  />
                </button>
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ dateStringCompanion }} {{ dateString | formatDate }}
                    </span>
                  </template>
                  <v-date-picker
                    v-model="dateString"
                    no-title
                    @input="menuDate = false"
                    locale="es-es"
                    :max="today"
                    full-width
                  ></v-date-picker>
                </v-menu>
                <button
                  v-if="dateString < today"
                  v-on:click="load_auctions_rows(1)"
                >
                  <img
                    src="../assets/i/icons/arrow_down.svg"
                    class="icon-row-right"
                  />
                </button>
              </div>
              <div v-if="loadingAuctions" class="loading-auctions">
                <v-progress-circular
                  :size="200"
                  :width="10"
                  color="white"
                  indeterminate
                >
                  Cargando...
                </v-progress-circular>
              </div>
              <div v-else>
                <table
                  v-if="auctions_grouped_formatted.length > 0"
                  class="table-auctions"
                >
                  <tr class="withoutBorder">
                    <th class="col-header-name">Especie</th>
                    <th class="col-header-amount">Kgs</th>
                    <th class="col-header-price">Precio</th>
                    <th class="col-header-total-price">Importe</th>
                  </tr>
                  <tr>
                    <td colspan="4" class="td-aux">
                      <div class="table-auctions-container">
                        <template
                          v-for="(
                            auction, index_auction
                          ) in auctions_grouped_formatted"
                        >
                          <tr
                            :key="'1tr-' + index_auction"
                            :class="{
                              rowFirstGrouped: index_auction == 0,
                              rowFirst: auction.first == 1,
                              rowSubtotal: auction.last == 1,
                              withoutBorder:
                                rowActiveControl[index_auction] &&
                                auction.specie_caliber != 0,
                            }"
                            @click="changeRowActive(index_auction)"
                          >
                            <td class="col-name">
                              <span v-if="auction.specie_caliber != 0">
                                {{ auction.specie_name }} ({{
                                  auction.specie_caliber
                                }})
                              </span>
                              <span v-else-if="auction.first != 1 && auction.last == 1">
                                Subtotal {{ auction.specie_name }}
                              </span>
                              <span v-else>
                                {{ auction.specie_name }}
                              </span>
                            </td>
                            <td class="col-amount">
                              {{ auction.amount | formatSecondDecimal }}
                            </td>
                            <td class="col-price">
                              {{ auction.price | formatSecondDecimal }}
                            </td>
                            <td class="col-total-price">
                              {{ auction.total_price | formatSecondDecimal }}
                            </td>
                          </tr>
                          <tr
                            :key="'2tr-' + index_auction"
                            v-if="auction.batch && auction.seller"
                            v-show="rowActiveControl[index_auction]"
                            @click="changeRowActive(index_auction)"
                          >
                            <td colspan="4">
                              <div class="row-detail">
                                <div>Lote: {{ auction.batch }}</div>
                                <div>Proveedor: {{ auction.seller }}</div>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </div>
                    </td>
                  </tr>
                </table>
                <div v-else class="no-auctions">No hay datos</div>
                <div
                  v-if="auctions_grouped_formatted.length > 0"
                  class="total-auctions"
                >
                  <table>
                    <tr>
                      <th colspan="2">TOTAL COMPRAS</th>
                    </tr>
                    <tr>
                      <td>
                        Cantidad <br />
                        {{ auctions_total.amount | formatSecondDecimal }} Kg
                      </td>
                      <td>
                        Importe <br />
                        {{ auctions_total.price | formatSecondDecimal }} €
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import LoadingComponent from '../components/LoadingComponent.vue'

export default {
  name: 'compras',
  components: {
    LoadingComponent
  },
  data () {
    return {
      show: false,
      title: 'Mis compras',
      date: new Date(),
      dateString: null,
      dateStringCompanion: '',
      menuDate: false,
      arrowControl: true,
      rowActiveControl: [],
      loadingAuctions: false,
      timer: null
    }
  },
  props: {
    portId: {
      type: [Number, String],
      required: true
    },
    aliasId: {
      type: [Number, String],
      required: true
    }
  },
  watch: {
    dateString () {
      this.rowActiveControl = []
      this.load_auctions()
    }
  },
  computed: {
    today () {
      return this.format_date(new Date())
    },
    yesterday () {
      return new Date(new Date().setDate(new Date().getDate() - 1))
    },
    auctions () {
      return this.$store.state.auctions_day
    },
    auctions_formatted () {
      let auctionsF = []
      this.auctions.forEach(function (auction) {
        let seller =
          auction.ship != null ? auction.ship.name : auction.seller_entity.name
        seller = seller != '' ? seller : auction.seller_entity.name
        auctionsF.push({
          specie_id: auction.specie.id,
          specie_name: auction.specie.association_name,
          specie_caliber: auction.specie_caliber_code ? auction.specie_caliber_code : 0,
          amount: auction.amount,
          price: auction.price,
          total_price: auction.price * auction.amount,
          coin_code: auction.coin_code,
          batch: auction.specie_batch,
          seller: seller
        })
      })
      return auctionsF
        .sort(this.sortAuctionsPrice)
        .sort(this.sortAuctionsCaliber)
        .sort(this.sortAuctionsName)
    },
    auctions_grouped_formatted () {
      let auctionsGF = []
      let auctionGF = null
      this.auctions_formatted.forEach(function (auction) {
        auctionGF = auctionsGF.find((x) => x.specie_id == auction.specie_id)
        let sumAmount = 0
        let sumTotalPrice = 0
        if (auctionGF != undefined) {
          auctionGF.auctions.push(auction)

          auctionGF.sumAmount += auction.amount
          sumAmount = auctionGF.sumAmount

          auctionGF.sumTotalPrice += auction.total_price
          sumTotalPrice = auctionGF.sumTotalPrice

          auctionGF.half_price = sumTotalPrice / sumAmount
        } else {
          sumAmount = auction.amount
          sumTotalPrice = auction.total_price

          auctionGF = {
            specie_id: auction.specie_id,
            specie_name: auction.specie_name,
            auctions: [],
            sumAmount: sumAmount,
            half_price: sumTotalPrice / sumAmount,
            sumTotalPrice: sumTotalPrice
          }
          auction.first = 1
          auctionGF.auctions.push(auction)
          auctionsGF.push(auctionGF)
        }
      })
      auctionsGF.forEach(function (value) {
        if (value.auctions.length > 1) {
          value.auctions.push({
            amount: value.sumAmount,
            coin_code: 'EUR',
            price: value.half_price,
            specie_caliber: 0,
            specie_id: value.specie_id,
            specie_name: value.specie_name,
            total_price: value.sumTotalPrice,
            last: 1
          })
        } else {
          value.auctions[0].last = 1
        }
      })
      let auctionsGFFix = []
      auctionsGF.forEach(function (x) {
        x.auctions.forEach(function (y) {
          auctionsGFFix.push(y)
        })
      })
      parent = this
      if (this.rowActiveControl.length !== auctionsGFFix.length) {
        auctionsGFFix.forEach(function () {
          parent.rowActiveControl.push(false)
        })
      }
      return auctionsGFFix
    },
    auctions_total () {
      let auctionsT = {
        amount: 0,
        price: 0
      }
      this.auctions_formatted.forEach(function (auction) {
        auctionsT.amount += auction.amount
        auctionsT.price += auction.total_price
      })
      return auctionsT
    }
  },
  methods: {
    PadLeft (value, length) {
      return value.toString().length < length
        ? this.PadLeft('0' + value, length)
        : value
    },
    format_date (localDate) {
      return (
        this.PadLeft(localDate.getFullYear(), 2) +
        '-' +
        this.PadLeft(localDate.getMonth() + 1, 2) +
        '-' +
        this.PadLeft(localDate.getDate(), 2)
      )
    },
    load_auctions_rows (x) {
      if (this.arrowControl) {
        this.arrowControl = false
        this.date.setDate(this.date.getDate() + x)
        this.dateString = this.format_date(this.date)
        setTimeout(() => {
          this.arrowControl = true
        }, 100)
      }
    },
    sortAuctionsPrice (x, y) {
      return x.price - y.price
    },
    sortAuctionsName (x, y) {
      return x.specie_name.localeCompare(y.specie_name)
    },
    sortAuctionsCaliber (x, y) {
      return x.specie_caliber - y.specie_caliber;
    },
    changeRowActive (index) {
      let currentValue = this.rowActiveControl[index]
      parent = this
      this.rowActiveControl.forEach(function (valor, indice, array) {
        parent.$set(parent.rowActiveControl, indice, false)
      })
      this.$set(this.rowActiveControl, index, (!currentValue))
    },
    load_auctions () {
      this.loadingAuctions = true
      let dateStringControl = this.dateString
      this.date = new Date(this.dateString)
      if (this.format_date(this.date) == this.today) {
        this.dateStringCompanion = 'HOY'
      } else if (this.format_date(this.date) == this.format_date(this.yesterday)) {
        this.dateStringCompanion = 'AYER'
      } else {
        this.dateStringCompanion = 'DÍA'
      }
      setTimeout(() => {
        if (dateStringControl === this.dateString) {
          this.$api
            .auctions_day(this.dateString, 0, 'c', this.portId, this.aliasId)
            .then((response) => {
              this.loadingAuctions = false
              clearInterval(this.timer)
              if (this.format_date(this.date) == this.today) {
                this.timer = setInterval(this.load_auctions, 30000)
              }
            })
            .catch((error) => {
              if (error.response.status === 403) {
                this.$store.commit('token', null)
                this.$router.push('/login')
              }
            })
        }
      }, 500)
    }
  },
  beforeMount () {
    this.dateString = this.format_date(this.date)
  },
  mounted () {
    this.show = true
    this.$store.commit('pageTitle', 'Compras')
    this.$store.commit('pageIcon', 'sales.svg')
    this.$gtag.pageview(this.$route)
    document.addEventListener('visibilitychange', this.load_auctions, true)
  },
  destroyed () {
    clearInterval(this.timer)
    document.removeEventListener('visibilitychange', this.load_auctions, true)
  }
}
</script>
