<template>
  <v-main class="page-maps">
    <!-- filtro -->
    <div class="filter">
      <div>
        <v-select
          class="map-specie-filter"
          :items="species_data"
          label="especie"
          item-value="id"
          @change="selectSpecie($event)"
          append-icon="search"
          height="16px"
          dense
          solo
        >
          <template slot="item" slot-scope="data">
            {{ data.item.name }} ({{ data.item.fao_code }})
          </template>
          <template slot="selection" slot-scope="data">
            {{ data.item.name }} ({{ data.item.fao_code }})
          </template>
        </v-select>
      </div>

      <div class="time-filter">
        <v-btn-toggle id="toggle-group" mandatory>
          <v-btn @click="selectPeriod('week')">Semana</v-btn>
          <v-btn @click="selectPeriod('month')">Mes</v-btn>
          <v-btn @click="selectPeriod('year')">Año</v-btn>
        </v-btn-toggle>
      </div>
    </div>

    <!-- map -->
    <div class="legend-container">
      <svg version="1.1" id="heatmap-legend" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 805 195" style="enable-background:new 0 0 805 195;" xml:space="preserve">
        <g id="Color_Palette">
          <rect x="690" y="80" class="st0" width="115" height="115"/>
          <rect x="575" y="80" class="st1" width="115" height="115"/>
          <rect x="460" y="80" class="st2" width="115" height="115"/>
          <rect x="345" y="80" class="st3" width="115" height="115"/>
          <rect x="230" y="80" class="st4" width="115" height="115"/>
          <rect x="115" y="80" class="st5" width="115" height="115"/>
          <rect y="80" class="st6" width="115" height="115"/>
        </g>
        <path id="Add" d="M770,34.1h-16.9V17.2c0-2.1-1.7-3.8-3.8-3.8h-3.8c-2.1,0-3.8,1.7-3.8,3.8v16.9H725c-2.1,0-3.8,1.7-3.8,3.8v3.8
          c0,2.1,1.7,3.8,3.8,3.8h16.9v16.9c0,2.1,1.7,3.8,3.8,3.8h3.8c2.1,0,3.8-1.7,3.8-3.8V45.4H770c2.1,0,3.8-1.7,3.8-3.8v-3.8
          C773.8,35.8,772.1,34.1,770,34.1z"/>
        <path id="Subst" d="M80,45.4c2.1,0,3.8-1.7,3.8-3.8v-3.8c0-2.1-1.7-3.8-3.8-3.8H35c-2.1,0-3.8,1.7-3.8,3.8v3.8
          c0,2.1,1.7,3.8,3.8,3.8H80z"/>
      </svg>

      <svg v-if="checkModules('app-zonas-prohibidas')"
        version="1.1" id="forbidden-legend" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 805 195" style="enable-background:new 0 0 805 195;" xml:space="preserve">
        <g id="Color_Palette">
          <rect y="80" class="legend" width="200" height="115"/>
          <text class="text-legend" font-size="5em" x="250" y="160" height="115" width="500">Zonas vetadas</text>
        </g>
      </svg>

    </div>
    <google-map :captures.sync="captures_data" :forbiddenZones="forbidden_zones"></google-map>
    <div v-if="totalCaptures != null" id="captures-counter">
      <v-label>{{ getSpecieName }}</v-label>
      <v-label>{{ totalCaptures }}</v-label>
    </div>
  </v-main>
</template>

<script>
import { text } from 'body-parser'
import GoogleMap from '../components/GoogleMap.vue'

export default {
  name: 'captures-map',
  components: {
    GoogleMap,
    text
  },
  data () {
    return {
      show: false,
      title: 'Mapa Capturas',
      specieId: null,
      period: 'week',
      species_data: [],
      captures_data: [],
      forbidden_zones: []
    }
  },
  computed: {
    totalCaptures () {
      let totalCaptures = 0
      if (this.specieId) {
        this.captures_data.forEach((capturesZone) => {
          totalCaptures += parseInt(capturesZone.captures)
        })
        if (totalCaptures === 0) {
          totalCaptures = null
        } else {
          totalCaptures = this.addDots(totalCaptures).toString() + ' Kg'
        }
      } else {
        totalCaptures = null
      }
      return totalCaptures
    },
    getSpecieName () {
      let specieName = null
      this.species_data.forEach((capturedSpecie) => {
        if (capturedSpecie.id === this.specieId) {
          specieName = capturedSpecie.name
        }
      })
      return specieName
    },
    modules () {
      return this.$store.state.modules
    }
  },
  methods: {
    selectSpecie (event) {
      this.specieId = event
      this.searchCaptures()
    },
    selectPeriod (period) {
      this.period = period
      this.searchCaptures()
    },
    searchCaptures () {
      if (!this.specieId) {
        return
      }

      if (['week', 'month', 'year'].indexOf(this.period) < 0) {
        this.period = 'week'
      }

      this.$api
        .capturesMap(this.specieId, this.period)
        .then((response) => {
          this.captures_data = response.data
        })
        .catch((error) => {
          if (error.response.status === 403) {
            window.localStorage.clear()
            this.$router.push('/login')
          }
        })
    },
    checkModules (moduleName) {
      return this.modules.map((e) => e.name).indexOf(moduleName) > -1
    },
    getForbiddenZones () {
      if (this.checkModules('app-zonas-prohibidas')) {
        this.$api
          .forbiddenZones()
          .then((response) => {
            this.forbidden_zones = response.data
            // console.table(this.response);
          })
          .catch((error) => {
            if (error.response.status === 403) {
              this.$store.commit('token', null)
              this.$router.push('/login')
            }
          })
      }
    },
    addDots (number) {
      return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
  },
  mounted () {
    this.$api
      .speciesCaptures()
      .then((response) => {
        this.species_data = response.data
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
    this.show = true
    this.$store.commit('pageTitle', 'Mapas')
    this.$store.commit('pageIcon', 'mapas.svg')
    this.getForbiddenZones()
  }
}
</script>
