<template>
  <v-main class="page-stocks">
    <v-container fluid fill-height>
      <v-layout justify-center>
        <v-flex class="page-stocks-panel" xs12 sm10>
          <h2 class="page-stocks-ship-name">{{ ship.name }}</h2>
          <h3 class="page-stocks-text">Selecciona una cuota:</h3>
          <transition
            name="slideInUp"
            v-for="(quota, index) in quotas"
            :key="index"
          >
            <stock
              v-show="show"
              :quota="quota"
              :key="index"
              class="mb-4"
            ></stock>
          </transition>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import Stock from '../components/Stock'
export default {
  name: 'home',
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  components: {
    Stock
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    gestcuota () {
      const index = this.$store.state.modules.map((e) => e.name).indexOf('gestcuotas')
      return index > -1
    },
    ship () {
      const ships = this.$store.state.ships.results
      for (let i in ships) {
        if (ships[i].id === this.id) {
          return ships[i]
        }
      }
      return null
    },
    quotas () {
      if (this.$store.state.quotas.results !== undefined) {
        return this.$store.state.quotas.results
      } else {
        return this.$store.state.quotas
      }
    }
  },
  methods: {
    sortStocks (quotaX, quotaY) {
      return quotaX.stock.name.localeCompare(quotaY.stock.name)
    }
  },
  beforeMount () {
    if (this.quotas.length === 1) {
      this.$router.push({
        name: 'quota',
        params: {
          id: ((this.quotas[0].id !== undefined && this.quotas[0].id !== null) ? this.quotas[0].id : 'none'),
          id_gestcuota: ((this.quotas[0].id_gestcuota !== undefined) ? this.quotas[0].id_gestcuota : 'none'),
          shipId: this.ship.id
        }
      })
    } else if (this.quotas.length === 0) {
      this.$router.back()
    }
  },
  mounted () {
    this.show = true
    this.$store.commit('pageTitle', 'Cuotas')
    this.$store.commit('pageIcon', 'quotas.svg')
  }
}
</script>
