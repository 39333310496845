<template>
  <v-card class="stock v-card-blue">
    <v-container fill-height fluid @click="gotoStockDetail()" class="header">
      <v-layout fill-height>
        <span class="headline">
          {{ quota.stock.name }}
        </span>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'stock',
  props: {
    quota: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  methods: {
    gotoStockDetail () {
      this.$router.push({
        name: 'quota',
        params: {
          id: ((this.quota.id !== undefined) ? this.quota.id : 'none'),
          id_gestcuota: ((this.quota.id_gestcuota !== undefined) ? this.quota.id_gestcuota : 'none'),
          shipId: this.quota.ships[0].id
        }
      })
    }
  }
}
</script>
