<template>
  <v-main v-if="show" class="page-ships">
    <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <v-flex class="page-ships-panel" xs12 sm10>
          <h3 class="page-ships-text">Selecciona un puerto:</h3>
          <transition
            name="slideInUp"
            v-for="(port, index) in ports"
            :key="index"
          >
            <v-card
              v-show="show"
              flat
              class="v-card-blue ship mb-4"
              v-on:click="goToSubasta(port.id, port.alias)"
            >
              <v-card-text>
                <span class="headline">{{ port.alias }}</span>
              </v-card-text>
            </v-card>
          </transition>

        </v-flex>

      </v-layout>
    </v-container>
  </v-main>

</template>

<script>
export default {
  name: 'subastaports',
  components: {},
  data () {
    return {
      show: false,
      title: 'Mis puertos',
      timeout: null
    }
  },
  computed: {
    ports () {
      return this.$store.state.global_auctions_stats_ports.sort(this.sortPorts)
    }
  },
  methods: {
    goToSubasta (portId, portAlias) {
      this.$router.push({
        name: 'subasta',
        params: {
          portId: portId,
          portAlias: portAlias
        }
      })
    },
    sortPorts (x, y) {
      return x.alias.localeCompare(y.alias)
    }
  },
  beforeMount () {
    this.$api.global_auctions_stats_ports()
      .then(() => {
        if (!this.ports || this.ports.length === 0) {
          this.$router.back()
        } else if (this.ports.length === 1) {
          this.$router.push({
            name: 'subasta',
            params: {
              portId: this.ports[0].id,
              portAlias: this.ports[0].alias
            }
          })
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
  },
  mounted () {
    this.show = true
    this.$store.commit('pageTitle', 'Subasta')
    this.$store.commit('pageIcon', 'auctions.svg')
  }
}
</script>
