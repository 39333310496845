<template>
  <transition name="slide-down">
    <div v-if="show" id="splash">
      <div class="content">
        <div class="logo-wrapper">
          <img src="../assets/i/deo_no_o.png" alt="DEO" class="logo"/>
          <img src="../assets/i/spinner.png" alt="DEO" class="spinner"/>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: [
    'time'
  ],
  data () {
    return {
      show: 1
    }
  },
  mounted () {
    setTimeout(() => {
      this.show = false
    }, this.time)
  }
}
</script>
