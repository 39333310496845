<template>
  <v-main v-if="show" class="page-ships">
    <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <v-flex class="page-ships-panel" xs12 sm10>
          <h3 class="page-ships-text">Selecciona un buque:</h3>
          <transition
            name="slideInUp"
            v-for="(ship, index) in this.ships"
            :key="index"
          >
            <v-card
              v-show="show"
              flat
              class="v-card-blue ship mb-4"
              @click.native="gotoShip(ship.id)"
            >
              <v-card-text>
                <span class="headline">{{ ship.name }}</span>
              </v-card-text>
            </v-card>
          </transition>

        </v-flex>

      </v-layout>
    </v-container>

    <v-alert id="alert_no_quota" :value="alert" dark transition="scale-transition">No existen cuotas activas para ese barco en el año actual</v-alert>

  </v-main>

</template>

<script>

export default {
  name: 'ships',
  props: {},
  components: {},
  data () {
    return {
      show: false,
      title: 'Mis barcos',
      alert: false,
      timeout: null
    }
  },
  computed: {
    gestcuota () {
      const index = this.$store.state.modules.map((e) => e.name).indexOf('gestcuotas')
      return index > -1
    },
    cuotasVentas () {
      const index = this.$store.state.modules.map((e) => e.name).indexOf('app-cuotas-ventas')
      return index > -1
    },
    ships () {
      if (this.$store.state.ships.results !== undefined) {
        return this.$store.state.ships.results
      } else {
        return this.$store.state.ships
      }
    }
  },
  methods: {
    gotoShip (id) {
      this.$api.quotas(id).then(() => {
        const quotas = this.$store.state.quotas
        if (this.gestcuota) {
          this.$api.gestcuotas(id).then(() => {
            const gestcuotas = this.$store.state.gestcuotas
            gestcuotas.forEach(function (gestcuota) {
              var quotaExists = quotas.find(quota => (quota.stock.id === gestcuota.stock.id))
              let quota = {
                amount: null,
                consumed: null,
                consumed_by_month: [],
                get_quota_type_display: null,
                id: null,
                id_gestcuota: null,
                movements: null,
                name: null,
                quota_type: null,
                real_amount: null,
                ships: [],
                stock: null,
                year: null
              }
              if (!quotaExists) {
                quota.amount = gestcuota.monthData[0].adapted_amount
                quota.consumed = gestcuota.monthData[0].consumed
                quota.get_quota_type_display = 'Individual'
                quota.id_gestcuota = gestcuota.id
                quota.quota_type = 'individual'
                quota.real_amount = gestcuota.monthData[0].adapted_amount
                quota.stock = gestcuota.stock
                quota.ships.push(gestcuota.ship)
                quota.year = gestcuota.campaign_year
                quotas.push(quota)
              } else {
                quotaExists.amount = gestcuota.monthData[0].adapted_amount
                quotaExists.consumed = gestcuota.monthData[0].consumed
                quotaExists.get_quota_type_display = 'Individual'
                quotaExists.id_gestcuota = gestcuota.id
                quotaExists.quota_type = 'individual'
                quotaExists.real_amount = gestcuota.monthData[0].adapted_amount
              }
            })
            if (quotas.length > 0) {
              this.$router.push({
                name: 'stocks',
                params: { id }
              })
            } else {
              if (this.alert) {
                clearTimeout(this.timeout)
                this.alert = !this.alert
              }
              this.alert = !this.alert
              this.timeout = setTimeout(() => {
                this.alert = !this.alert
              }, 4000)
            }
          })
            .catch((error) => {
              if (error.response.status === '403') {
                this.$store.commit('token', null)
                this.$router.push('/login')
              }
            })
        } else if (this.cuotasVentas) {
          const year = new Date().getFullYear()
          this.$api.quotas_sales(id, year).then(() => {
            const quotasSales = this.$store.state.quotas_sales
            quotasSales.forEach(function (quota) {
              let quotaExists = quotas.find(quota => (quota.stock.id === quotasSales.quota__stock__id))
              if (!quotaExists) {
                quotas.push(quota)
              }
            })
            if (quotas.length > 0) {
              this.$router.push({
                name: 'stocks',
                params: { id }
              })
            } else {
              if (this.alert) {
                clearTimeout(this.timeout)
                this.alert = !this.alert
              }
              this.alert = !this.alert
              this.timeout = setTimeout(() => {
                this.alert = !this.alert
              }, 4000)
            }
          })
        } else {
          if (quotas.length > 0) {
            this.$router.push({
              name: 'stocks',
              params: { id }
            })
          } else {
            if (this.alert) {
              clearTimeout(this.timeout)
              this.alert = !this.alert
            }
            this.alert = !this.alert
            this.timeout = setTimeout(() => {
              this.alert = !this.alert
            }, 4000)
          }
        }
      }).catch((error) => {
        if (error.response.status === '403') {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
    },
    sortShips (x, y) {
      return x.name.localeCompare(y.name)
    }
  },
  beforeMount () {
    this.$api.ships()
      .then(() => {
        if (this.ships.length === 1) {
          this.gotoShip(this.ships[0].id)
        } else if (this.ships.length === 0) {
          this.$router.back()
        }
      })
      .catch((error) => {
        if (error.response.status === '403') {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
  },
  mounted () {
    this.show = true
    this.$store.commit('pageTitle', 'Cuotas')
    this.$store.commit('pageIcon', 'quotas.svg')
  }
}
</script>
