var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading-component'),_c('v-main',{staticClass:"page-compras"},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":""}},[_c('v-container',{staticClass:"container-compras"},[_c('div',{staticClass:"botones-fechas"},[_c('button',{on:{"click":function($event){return _vm.load_auctions_rows(-1)}}},[_c('img',{staticClass:"icon-row-left",attrs:{"src":require("../assets/i/icons/arrow_down.svg")}})]),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.dateStringCompanion)+" "+_vm._s(_vm._f("formatDate")(_vm.dateString))+" ")])]}}]),model:{value:(_vm.menuDate),callback:function ($$v) {_vm.menuDate=$$v},expression:"menuDate"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es-es","max":_vm.today,"full-width":""},on:{"input":function($event){_vm.menuDate = false}},model:{value:(_vm.dateString),callback:function ($$v) {_vm.dateString=$$v},expression:"dateString"}})],1),(_vm.dateString < _vm.today)?_c('button',{on:{"click":function($event){return _vm.load_auctions_rows(1)}}},[_c('img',{staticClass:"icon-row-right",attrs:{"src":require("../assets/i/icons/arrow_down.svg")}})]):_vm._e()],1),(_vm.loadingAuctions)?_c('div',{staticClass:"loading-auctions"},[_c('v-progress-circular',{attrs:{"size":200,"width":10,"color":"white","indeterminate":""}},[_vm._v(" Cargando... ")])],1):_c('div',[(_vm.auctions_grouped_formatted.length > 0)?_c('table',{staticClass:"table-auctions"},[_c('tr',{staticClass:"withoutBorder"},[_c('th',{staticClass:"col-header-name"},[_vm._v("Especie")]),_c('th',{staticClass:"col-header-amount"},[_vm._v("Kgs")]),_c('th',{staticClass:"col-header-price"},[_vm._v("Precio")]),_c('th',{staticClass:"col-header-total-price"},[_vm._v("Importe")])]),_c('tr',[_c('td',{staticClass:"td-aux",attrs:{"colspan":"4"}},[_c('div',{staticClass:"table-auctions-container"},[_vm._l((_vm.auctions_grouped_formatted),function(auction,index_auction){return [_c('tr',{key:'1tr-' + index_auction,class:{
                            rowFirstGrouped: index_auction == 0,
                            rowFirst: auction.first == 1,
                            rowSubtotal: auction.last == 1,
                            withoutBorder:
                              _vm.rowActiveControl[index_auction] &&
                              auction.specie_caliber != 0,
                          },on:{"click":function($event){return _vm.changeRowActive(index_auction)}}},[_c('td',{staticClass:"col-name"},[(auction.specie_caliber != 0)?_c('span',[_vm._v(" "+_vm._s(auction.specie_name)+" ("+_vm._s(auction.specie_caliber)+") ")]):(auction.first != 1 && auction.last == 1)?_c('span',[_vm._v(" Subtotal "+_vm._s(auction.specie_name)+" ")]):_c('span',[_vm._v(" "+_vm._s(auction.specie_name)+" ")])]),_c('td',{staticClass:"col-amount"},[_vm._v(" "+_vm._s(_vm._f("formatSecondDecimal")(auction.amount))+" ")]),_c('td',{staticClass:"col-price"},[_vm._v(" "+_vm._s(_vm._f("formatSecondDecimal")(auction.price))+" ")]),_c('td',{staticClass:"col-total-price"},[_vm._v(" "+_vm._s(_vm._f("formatSecondDecimal")(auction.total_price))+" ")])]),(auction.batch && auction.seller)?_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.rowActiveControl[index_auction]),expression:"rowActiveControl[index_auction]"}],key:'2tr-' + index_auction,on:{"click":function($event){return _vm.changeRowActive(index_auction)}}},[_c('td',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"row-detail"},[_c('div',[_vm._v("Lote: "+_vm._s(auction.batch))]),_c('div',[_vm._v("Proveedor: "+_vm._s(auction.seller))])])])]):_vm._e()]})],2)])])]):_c('div',{staticClass:"no-auctions"},[_vm._v("No hay datos")]),(_vm.auctions_grouped_formatted.length > 0)?_c('div',{staticClass:"total-auctions"},[_c('table',[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v("TOTAL COMPRAS")])]),_c('tr',[_c('td',[_vm._v(" Cantidad "),_c('br'),_vm._v(" "+_vm._s(_vm._f("formatSecondDecimal")(_vm.auctions_total.amount))+" Kg ")]),_c('td',[_vm._v(" Importe "),_c('br'),_vm._v(" "+_vm._s(_vm._f("formatSecondDecimal")(_vm.auctions_total.price))+" € ")])])])]):_vm._e()])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }