<template>
  <div class="main-menu">
    <div class="user-section">
      <div class="logo-section">
        <img src="../assets/i/deo_logo.png" alt="">
      </div>
      <div class="user-info">
        <span class="username-label">{{ ship_owner }}</span>
        <span class="association-label">{{ association }}</span>
      </div>
    </div>

    <div class="modules">
      <modules ref="modules"></modules>
    </div>

    <div class="copyright-section">
      <!-- association_id 8 because is the id of Burela -->
      <div v-if="association_id == 8" class="logos">
        <img
          src="../assets/i/icons/fempa_white_logo.png"
          alt="Icono Femp"
          class="femp_logo"
        />
        <img
          src="../assets/i/icons/xunta_white_logo.png"
          alt="Icono Xunta"
          class="xunta_logo"
        />
      </div>
      <div>© {{ getCopyDate }} <span id="strong">SEALAB SOLUTIONS</span></div>
    </div>
  </div>
</template>

<script>
import Modules from '../components/Modules'

export default {
  components: {
    modules: Modules
  },
  name: 'main-menu',
  data () {
    return {
      title: 'Menú',
      ship_owner: '',
      association: '',
      association_id: ''
    }
  },
  computed: {
    getCopyDate () {
      return new Date().getFullYear()
    }
  },
  mounted () {
    this.$api
      .modules()
      .then((response) => {
        this.ship_owner = response.data.ship_owner.name
        this.association = response.data.association.name
        this.association_id = response.data.association.id
        this.$store.commit('association', this.association)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
    this.$api
      .hidden_modules()
      .then((response) => {})
      .catch((error) => {
        if (error.response.status === 403) {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
    this.$store.commit('pageTitle', 'Menú')
  }
}
</script>
