import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import store from '../../store'

Vue.use(Vuex)

export default function Api () {
  return {
    loading (state) {
      store.commit('loading', state)
    },
    apiBase () {
      return process.env.VUE_APP_API_URL
    },
    throwError (error) {
      if ([403, 404].indexOf(error.response.status) > -1) {
        // store.commit('token', null)
        this.$router.push('/login')
      }

      store.dispatch('showGlobalModal', {
        'title': 'Error',
        'text': `Ha ocurrido un error al descargar los datos`
      })
      this.loading(false)
      throw error
    },
    getRequest (url, storeName, forzeLoadingScreen = false) {
      if (forzeLoadingScreen) {
        return axios
          .get(
            this.apiBase() + url, this.getAuthHeader()
          )
          .then(response => {
            store.commit(storeName, response.data)
            this.loading(false)
            return response
          })
          .catch(error => {
            this.throwError(error)
          })
      } else {
        return axios
          .get(
            this.apiBase() + url, this.getAuthHeader()
          )
          .then(response => {
            store.commit(storeName, response.data)
            return response
          })
          .catch(error => {
            this.throwError(error)
          })
          .finally(this.loading(false))
      }
    },
    postRequest (url, data, storeName = '') {
      return axios
        .post(

          this.apiBase() + url, data, this.getAuthHeader()

        )
        .then(response => {
          // store.commit(storeName, response.data)
          // this.loading(false)
          return response
        })
        /* .catch(error => {
          // this.throwError(error)
          console.log(error)
        }) */
        .finally(this.loading(false))
    },
    patchRequest (url, data, storeName = '') {
      return axios
        .patch(
          this.apiBase() + url, data, this.getAuthHeader()
        )
        .then(response => {
          // store.commit(storeName, response.data)
          // this.loading(false)
          return response
        })
      /*   .catch(error => {
          //this.throwError(error)
          console.log(error)
        }) */
        .finally(this.loading(false))
    },
    getAuthHeader (segment) {
      return {
        headers: {
          'Authorization': `Token ${store.state.token}`
        }
      }
    },
    pinRequest (mobile, forcedSMS = false) {
      this.loading(true)

      const data = {
        mobile_phone_number: mobile,
        platform: 'deo',
        forced_sms: forcedSMS
      }

      return axios
        .post(
          this.apiBase() + 'pin-request/',
          data
        )
        .then(response => {
          this.loading(false)
          return response
        })
        .catch(error => {
          this.loading(false)
          throw error
        })
    },
    pinLogin (mobile, pin) {
      this.loading(true)

      const data = {
        mobile_phone_number: mobile,
        pin: pin
      }

      return axios
        .post(
          this.apiBase() + 'pin-login/',
          data
        )
        .then(response => {
          this.loading(false)
          store.commit('token', response.data.token)
          return response
        })
        .catch(error => {
          this.loading(false)
          throw error
        })
    },
    captures () {
      this.loading(true)
      return this.getRequest('captures-app/', 'captures', true)
    },
    capturesMap (specie, period) {
      this.loading(true)
      var url = 'captures-cache/?specie=' + specie + '&' + period + '=2'
      return this.getRequest(url, 'capturesMap', true)
    },
    speciesCaptures () {
      this.loading(true)
      return this.getRequest('species-capture-map/', 'speciesCaptures', true)
    },
    ships () {
      this.loading(true)
      return this.getRequest('ships/?page=1&page_size=10000', 'ships', true)
    },
    quotas (ship) {
      this.loading(true)
      if (ship) {
        return this.getRequest('my-quotas/?ship=' + ship, 'quotas', true)
      } else {
        return this.getRequest('my-quotas/', 'quotas', true)
      }
    },
    quota_all_years (ship, stock) {
      this.loading(true)
      return this.getRequest(
        'my-quotas/?ship=' + ship + '&stock=' + stock,
        'quota_all_years', true
      )
    },
    gestcuotas (ship) {
      this.loading(true)
      return this.getRequest(
        'gestcuotas/?page=1&page_size=10000&ship=' + ship + '&campaign_year=' + new Date().getFullYear(),
        'gestcuotas', true
      )
    },
    gestcuota_all_years (ship, stock) {
      this.loading(true)
      return this.getRequest(
        'gestcuotas/?page=1&page_size=10000&ship=' + ship + '&stock=' + stock,
        'gestcuota_all_years', true
      )
    },
    quotas_sales (shipId, year = null) {
      this.loading(true)
      const endpoint = `quotas-sales-ship/?ship=${shipId}&${year ? 'year=' + year : ''}`
      return this.getRequest(endpoint, 'quotas_sales', true)
    },
    check_efforts (ship, year = '') {
      this.loading(true)
      if (ship) {
        return this.getRequest('find-efforts/?ship=' + ship + '&year=' + year, 'efforts', false)
      } else {
        return this.getRequest('find-efforts/', 'efforts', false)
      }
    },
    efforts (ship) {
      this.loading(true)
      return this.getRequest('efforts/?ship=' + ship, 'efforts', true)
    },
    gestefforts_ship (ship) {
      // this.loading(true)
      let url = 'gest-efforts-ship/?ship=' + ship
      return this.getRequest(url, 'gestefforts_ship', false)
    },
    avisos (page = 1) {
      this.loading(true)
      return this.getRequest('notifications-app/?page=' + page, 'avisos')
    },
    aviso (id) {
      this.loading(true)
      return this.getRequest('notifications-app/' + id + '/', 'aviso')
    },
    modules () {
      this.loading(true)
      return this.getRequest('profile/', 'modules')
    },
    hidden_modules () {
      this.loading(true)
      return this.getRequest('profile/', 'hidden_modules')
    },
    sizes () {
      this.loading(true)
      return this.getRequest('sizes/', 'sizes', true)
    },
    species () {
      this.loading(true)
      return this.getRequest('species-master/', 'species', true)
    },
    seatides () {
      this.loading(true)
      return this.getRequest('sea-tides-predict/', 'seatides', true)
    },
    expirations () {
      this.loading(true)
      return this.getRequest('expiration-ships-app/', 'expirations', true)
    },
    expirationSave (jsonData) {
      return axios
        .post(
          this.apiBase() + 'expiration-ships-app/' + jsonData.ship_expiration_id + '/change_date_expiration/',
          jsonData,
          this.getAuthHeader()
        )
        .catch(error => {
          this.loading(false)
          throw error
        })
    },
    entity_type () {
      this.loading(true)
      return this.getRequest('my-auctions/?only_entity_type=1', 'entity_type')
    },
    auctions_week (date, ship, entityType) {
      this.loading(true)
      return this.getRequest(
        'my-auctions/?date=' + date + '&week=1&ship=' + ship + '&entity_type=' + entityType,
        'auctions_week', false
      )
    },
    auctions_day (date, ship, entityType, portId = 0, aliasId = 0) {
      this.loading(true)
      return this.getRequest(
        'my-auctions/?date=' + date + '&day=1&ship=' + ship + '&entity_type=' + entityType +
        '&port_id=' + portId + '&alias_id=' + aliasId, 'auctions_day'
      )
    },
    global_auctions_stats (date, port) {
      this.loading(true)
      return this.getRequest(
        'global-auctions-stats/?auctions=1&date=' + date + '&port=' + port,
        'global_auctions_stats', true
      )
    },
    global_auctions_stats_ports () {
      this.loading(true)
      return this.getRequest(
        'global-auctions-stats/?ports=1',
        'global_auctions_stats_ports', true
      )
    },
    ports_remote_control_buyer_purchases () {
      this.loading(true)
      return this.getRequest('ports-remote-control-buyer-purchases/', 'ports_remote_control_buyer_purchases')
    },
    createStatistic (module) {
      this.loading(true)

      return this.postRequest(
        'statistics/create',
        {
          'aplicacion': 'deo',
          'module': module,
          'screen': store.screen,
          'browser': store.browser,
          'browserVersion': store.browserVersion,
          'browserMajorVersion': store.browserMajorVersion,
          'mobile': store.mobile,
          'os': store.os,
          'osVersion': store.osVersion,
          'cookies': store.cookies
        }
      )
    },
    updateStatistic (id) {
      this.loading(true)
      return this.patchRequest(
        'statistics/update/' + id,
        {
        }
      )
    },
    forbiddenZones () {
      this.loading(true)
      return this.getRequest('forbidden-zones/forbidden/', 'forbiddenZones')
    }
  }
}
