<template>
  <div>
    <loading-component></loading-component>
    <v-main class="page-seatides">
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-flex class="page-seatides-panel" xs12 sm10>
            <v-tabs v-model="currentTab">
              <v-tab v-for="(seatide, index) in seatides" :key="index" :href="'#tab-' + seatide.zona" class="v-tab">
                {{ seatide.zona }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="currentTab">
              <v-tab-item v-for="(seatide, index) in seatides" :key="index" :value="'tab-' + seatide.zona">
                <v-card flat height="80vh">
                  <v-card-text>
                    <div v-for="(predict, index) in seatide.predicts" :key="index" class="seatide">
                      <div class="seatide-panel">
                        <div class="seatide-panel-date">
                          <span v-if="isYesterday(predict.date)"> AYER </span>
                          <span v-else-if="isToday(predict.date)"> HOY </span>
                          <span v-else-if="isTomorrow(predict.date)"> MAÑANA </span>
                          <span v-else>{{ get_week_day(predict.date) }}</span> {{ predict.date | formatDate }}
                        </div>
                        <div class="seatide-panel-lunar-phase">
                            <img :src="getLunarPhaseUrl(predict.lunar_phase)" v-bind:alt="predict.lunar_phase" />
                            <div class="lunar-phase-text"> {{ predict.lunar_phase }} </div>
                        </div>
                      </div>
                      <div class="seatide-predict">
                        <table>
                          <tr v-if="predict.height_one">
                            <td v-if="predict.type_one == 'pleamar'">
                              <svg class="indicator-pm" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
                                <path class="st0" d="M224.5,123.5L69.8,370.6c-12.1,19.4,1.8,44.5,24.7,44.5h309.5c22.9,0,36.8-25.2,24.7-44.5L273.8,123.5
                                  C262.4,105.3,235.9,105.3,224.5,123.5z"/>
                              </svg>
                            </td>
                            <td v-if="predict.type_one == 'bajamar'">
                              <svg class="indicator-bm" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
                                <path class="st0" d="M224.5,123.5L69.8,370.6c-12.1,19.4,1.8,44.5,24.7,44.5h309.5c22.9,0,36.8-25.2,24.7-44.5L273.8,123.5
                                  C262.4,105.3,235.9,105.3,224.5,123.5z"/>
                              </svg>
                            </td>
                            <td>{{ predict.hour_one | formatHour }}</td>
                            <td>{{ set_coefficient(predict, 1) | formatSecondDecimal }}</td>
                          </tr>
                          <tr v-if="predict.height_two">
                            <td v-if="predict.type_two == 'pleamar'">
                              <svg class="indicator-pm" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
                                <path class="st0" d="M224.5,123.5L69.8,370.6c-12.1,19.4,1.8,44.5,24.7,44.5h309.5c22.9,0,36.8-25.2,24.7-44.5L273.8,123.5
                                  C262.4,105.3,235.9,105.3,224.5,123.5z"/>
                              </svg>
                            </td>
                            <td v-if="predict.type_two == 'bajamar'">
                              <svg class="indicator-bm" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
                                <path class="st0" d="M224.5,123.5L69.8,370.6c-12.1,19.4,1.8,44.5,24.7,44.5h309.5c22.9,0,36.8-25.2,24.7-44.5L273.8,123.5
                                  C262.4,105.3,235.9,105.3,224.5,123.5z"/>
                              </svg>
                            </td>
                            <td>{{ predict.hour_two | formatHour  }}</td>
                            <td>{{ set_coefficient(predict, 2) | formatSecondDecimal }}</td>
                          </tr>
                          <tr v-if="predict.height_three">
                            <td v-if="predict.type_three == 'pleamar'">
                              <svg class="indicator-pm" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
                                <path class="st0" d="M224.5,123.5L69.8,370.6c-12.1,19.4,1.8,44.5,24.7,44.5h309.5c22.9,0,36.8-25.2,24.7-44.5L273.8,123.5
                                  C262.4,105.3,235.9,105.3,224.5,123.5z"/>
                              </svg>
                            </td>
                            <td v-if="predict.type_three == 'bajamar'">
                              <svg class="indicator-bm" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
                                <path class="st0" d="M224.5,123.5L69.8,370.6c-12.1,19.4,1.8,44.5,24.7,44.5h309.5c22.9,0,36.8-25.2,24.7-44.5L273.8,123.5
                                  C262.4,105.3,235.9,105.3,224.5,123.5z"/>
                              </svg>
                            </td>
                            <td>{{ predict.hour_three | formatHour  }}</td>
                            <td>{{ set_coefficient(predict, 3) | formatSecondDecimal }}</td>
                          </tr>
                          <tr v-if="predict.height_four">
                            <td v-if="predict.type_four == 'pleamar'">
                              <svg class="indicator-pm" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
                                <path class="st0" d="M224.5,123.5L69.8,370.6c-12.1,19.4,1.8,44.5,24.7,44.5h309.5c22.9,0,36.8-25.2,24.7-44.5L273.8,123.5
                                  C262.4,105.3,235.9,105.3,224.5,123.5z"/>
                              </svg>
                            </td>
                            <td v-if="predict.type_four == 'bajamar'">
                              <svg class="indicator-bm" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
                                <path class="st0" d="M224.5,123.5L69.8,370.6c-12.1,19.4,1.8,44.5,24.7,44.5h309.5c22.9,0,36.8-25.2,24.7-44.5L273.8,123.5
                                  C262.4,105.3,235.9,105.3,224.5,123.5z"/>
                              </svg>
                            </td>
                            <td>{{ predict.hour_four | formatHour  }}</td>
                            <td>{{ set_coefficient(predict, 4) | formatSecondDecimal }}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import LoadingComponent from '../components/LoadingComponent.vue'
import moment from 'moment'
export default {
  name: 'seatides',
  components: {
    LoadingComponent
  },
  data () {
    return {
      seatides_data: {},
      currentTab: ''
    }
  },
  computed: {
    seatides () {
      return this.split_seatides(this.$store.state.seatides)
    }
  },
  methods: {
    split_seatides (seatides) {
      let splittedArray = []
      Array.from(seatides).forEach(function (x) {
        let existsInSplittedArray = false
        Array.from(splittedArray).forEach(function (y) {
          if (y.zona === x.sea_tide.name) {
            existsInSplittedArray = true
            y.predicts.push(x)
          }
        })
        if (!existsInSplittedArray) {
          splittedArray.push(
            {
              zona: x.sea_tide.name,
              predicts: []
            }
          )
          splittedArray.at(-1).predicts.push(x)
        }
      })
      return this.order_by_association(splittedArray)
    },
    get_seatides () {
      this.$api.seatides()
        .then((response) => {})
        .catch((error) => {
          if (error.response.status === '403') {
            this.$store.commit('token', null)
            this.$router.push('/login')
          }
        })
    },
    get_week_day (date) {
      return [
        'domingo',
        'lunes',
        'martes',
        'miércoles',
        'jueves',
        'viernes',
        'sábado'
      ][new Date(date).getDay()]
    },
    getLunarPhaseUrl (status) {
      var images = require.context('../assets/i/icons/lunar_phases', false, /\.svg$/)
      return images('./' + status + '.svg')
    },
    orderSeatides (x, y) {
      return x.zona.localeCompare(y.zona)
    },
    isToday (date) {
      var dateFormated = moment(date).format('YYYY-MM-DD')
      var todayFormated = moment(new Date()).format('YYYY-MM-DD')
      if (dateFormated === todayFormated) {
        return true
      } else {
        return false
      }
    },
    isYesterday (date) {
      var dateFormated = moment(date).format('YYYY-MM-DD')
      var dateYesterday = new Date()
      dateYesterday.setDate(dateYesterday.getDate() - 1)
      var yesterdayFormated = moment(dateYesterday).format('YYYY-MM-DD')
      if (dateFormated === yesterdayFormated) {
        return true
      } else {
        return false
      }
    },
    isTomorrow (date) {
      var dateFormated = moment(date).format('YYYY-MM-DD')
      var dateTomorrow = new Date()
      dateTomorrow.setDate(dateTomorrow.getDate() + 1)
      var tomorrowFormated = moment(dateTomorrow).format('YYYY-MM-DD')
      if (dateFormated === tomorrowFormated) {
        return true
      } else {
        return false
      }
    },
    order_by_association (array) {
      array.sort(this.orderSeatides)
      let association = this.$store.state.association
      let associationDefaultZone = ''

      switch (association) {
        case 'Tarifa':
          associationDefaultZone = 'Tarifa'
          break
        case 'Association_2':
          associationDefaultZone = 'Zone_Association_2'
          break
        default:
          associationDefaultZone = ''
          break
      }

      var parent = this
      array.forEach(function (valor, indice, array) {
        if (valor.zona === associationDefaultZone) {
          parent.currentTab = 'tab-' + valor.zona
          array.splice(indice, 1)
          array.unshift(valor)
        }
      })

      return array
    },
    set_coefficient (predict, hour) {
      let hourOne = String(predict.hour_one).substr(0, 2)
      let hourTwo = String(predict.hour_two).substr(0, 2)
      let hourThree = String(predict.hour_three).substr(0, 2)
      let hourFour = String(predict.hour_four).substr(0, 2)

      switch (hour) {
        case 1:
          if (hourOne < 12) {
            return predict.coefficient_one
          } else {
            return null
          }
        case 2:
          if (hourTwo >= 12) {
            return predict.coefficient_two
          } else {
            return null
          }
        case 3:
          if (hourThree >= 12 && hourTwo < 12) {
            return predict.coefficient_two
          } else {
            return null
          }
        case 4:
          if (hourFour >= 12 && hourThree < 12 && hourTwo < 12) {
            return predict.coefficient_two
          } else {
            return null
          }
        default:
          return null
      }
    }
  },
  mounted () {
    this.get_seatides()
    this.$store.commit('pageTitle', 'Mareas')
    this.$store.commit('pageIcon', 'seatides.svg')
  }
}
</script>
