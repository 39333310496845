<template>
  <div>
    <loading-component></loading-component>
    <v-main class="page-expirations">
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-flex xs12 sm10>
            <v-container class="container-ship-expirations">
              <transition
                name="slideInUp"
                v-for="(expiration, key_expiration) in expirationsApi"
                :key="key_expiration"
              >
                <div>
                  <div class="container-expiration" v-on:click="show_more">
                    <div class="expiration-title">
                      <div
                        class="expiration-mark"
                        :class="{
                          hidemark:
                            expirationCheck(
                              getOlderDate(expiration.ship_expirations),
                              expiration.expiration_details.reminder
                            ) != 'Caducado',
                        }"
                      ></div>
                      <div class="expiration-type">
                        {{ expiration.expiration_details.name }}
                      </div>
                      <div
                        :class="{
                          expired:
                            expirationCheck(
                              getOlderDate(expiration.ship_expirations),
                              expiration.expiration_details.reminder
                            ) == 'Caducado',
                        }"
                      >
                        {{
                          formatDate(getOlderDate(expiration.ship_expirations))
                        }}
                      </div>
                    </div>
                    <v-expand-transition>
                      <div class="expiration_details">
                        <div class="ship_expirations">
                          <div
                            name="slideInUp"
                            class="ship_expiration"
                            v-for="(ship, index_ship) in Object.keys(
                              expiration.ship_expirations
                            )"
                            :key="index_ship"
                          >
                            <p>{{ ship }}</p>
                            <div
                              class="expiration-wrapper"
                              v-for="(
                                ship_expiration, ship_expiration_index
                              ) in Object.values(
                                expiration.ship_expirations[ship]
                              )"
                              :key="ship_expiration_index"
                            >
                              <div
                                v-if="ship_expiration.expiration_date !== null"
                                class="expirations-info"
                              >
                                <div
                                  :class="{
                                    expired_expiration:
                                      expirationCheck(
                                        ship_expiration.expiration_date,
                                        expiration.expiration_details.reminder
                                      ) == 'Caducado',
                                  }"
                                  class="expired_expiration_size"
                                ></div>
                                <div class="flex">
                                  <div class="text">
                                  {{ ship_expiration.identifier_name }}
                                </div>
                                <div class="text" v-if="!expirationsEditable">
                                  {{ formatDate(ship_expiration.expiration_date) }}
                                </div>
                                <v-menu
                                  v-else
                                  :close-on-content-click="true"
                                  transition="scale-transition"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="ship_expiration.expiration_date"
                                      v-bind="attrs"
                                      v-on="on"
                                      type="date"
                                      readonly
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="ship_expiration.expiration_date"
                                    no-title
                                    locale="es-es"
                                    @input="handleChange(ship_expiration)"
                                  ></v-date-picker>
                                </v-menu>
                                </div>
                              </div>
                              <div
                                v-if="
                                  ship_expiration.expiration.expiration_type ===
                                  'balsa'
                                "
                                class="flex"
                              >
                              <div
                                  class="expired_expiration_size"
                                  :class="{
                                    expired_expiration:
                                      expirationCheck(
                                        ship_expiration.zafa_expiration_date,
                                        expiration.expiration_details.reminder
                                      ) == 'Caducado',
                                  }"
                                ></div>
                                <div class="flex">
                                  <div class="text">Zafa</div>
                                <div class="text" v-if="!expirationsEditable">
                                  {{
                                    formatDate(
                                      ship_expiration.zafa_expiration_date
                                    )
                                  }}
                                </div>
                                <v-menu
                                  v-else
                                  :close-on-content-click="true"
                                  transition="scale-transition"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="ship_expiration.zafa_expiration_date"
                                      v-bind="attrs"
                                      v-on="on"
                                      type="date"
                                      readonly
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="ship_expiration.zafa_expiration_date"
                                    no-title
                                    locale="es-es"
                                    @input="handleChange(ship_expiration)"
                                  ></v-date-picker>
                                </v-menu>
                                </div>

                              </div>
                              <div
                                v-if="
                                  ship_expiration_index <
                                  expiration.ship_expirations[ship].length - 1
                                "
                                class="separation_line"
                              ></div>
                            </div>
                          </div>
                        </div>

                        <div
                          v-if="
                            expiration.expiration_details
                              .competent_administration
                          "
                          class="ship_organismo"
                        >
                          <p>Organismo:</p>
                          <p class="text">
                            {{
                              expiration.expiration_details
                                .competent_administration
                            }}
                          </p>
                        </div>
                      </div>
                    </v-expand-transition>
                  </div>
                  <div
                    v-if="
                      show_separator(key_expiration)
                    "
                    class="separation_line"
                  ></div>
                </div>
              </transition>
              <v-container
                class="no_avisos"
                v-if="Object.keys(expirationsApi).length <= 0"
              >
                No tienes caducidades
              </v-container>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
      <v-dialog
        v-model="dialogEdit"
        persistent
      >
        <v-card>
          <v-card-title class="text-h5 justify-center">
            ¿Aceptar cambios?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="btn-expiration-edit-save" @click="saveExpirationEdit()">
              Si
            </v-btn>
            <v-btn class="btn-expiration-edit-cancel" @click="cancelExpirationEdit()">
              No
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </div>
</template>

<script>
import LoadingComponent from '../components/LoadingComponent.vue';
export default {
  name: 'expirationsView',
  components: {
    LoadingComponent
  },
  data () {
    return {
      show: false,
      title: 'Mis avisos',
      page: 1,
      expirations: undefined,
      dialogEdit: false,
      dataToEdit: {}
    }
  },
  computed: {
    expirationsEditable () {
      const index = this.$store.state.modules.map((e) => e.name).indexOf('app-caducidades-editables')
      return index > -1
    },
    expirationsApi () {
      let json = {}
      // Creación de array con agrupación de las caducidades barco por nombre de caducidad
      if (
        this.$store.state.expirations &&
        Array.isArray(this.$store.state.expirations)
      ) {
        for (
          let index = 0;
          index < this.$store.state.expirations.length;
          index++
        ) {
          const element = this.$store.state.expirations[index]
          if (json[element.expiration.name] === undefined) {
            json[element.expiration.name] = {
              expiration_details: '',
              ship_expirations: {}
            }
          }
          json[element.expiration.name].expiration_details = element.expiration
          const shipName = element.ship.name
          if (
            json[element.expiration.name].ship_expirations[shipName] === undefined
          ) {
            json[element.expiration.name].ship_expirations[shipName] = []
          }
          json[element.expiration.name].ship_expirations[shipName].push(element)
          json[element.expiration.name].expiration_details = {
            ...json[element.expiration.name].expiration_details,
            reminder: parseInt(
              Object.values(json[element.expiration.name].ship_expirations)[0][0]
                .reminder !== null
                ? Object.values(json[element.expiration.name].ship_expirations)[0][0].reminder.split(',')[0]
                : 30
            )
          }
        }
      }

      // Ordenar las claves del objeto json por nombre de caducidad
      const orderedJson = {}
      Object.keys(json)
        .sort()
        .forEach((key) => {
          orderedJson[key] = json[key]
        })

      return orderedJson
    }
  },
  methods: {
    show_separator (key) {
      return (Object.keys(this.expirationsApi).indexOf(key) < Object.keys(this.expirationsApi).length - 1)
    },
    cancelExpirationEdit () {
      this.expirations = JSON.parse(JSON.stringify(this.expirationsApi))
      this.dialogEdit = false
    },

    handleChange (shipExpiration) {
      this.dataToEdit = this.formatExpirationsData(shipExpiration)
      this.dialogEdit = true
    },

    formatExpirationsData (shipExpiration) {
      return {
        expiration_id: shipExpiration.expiration.id,
        ship_expiration_id: shipExpiration.id,
        ship_id: shipExpiration.ship.id,
        expiration_date: shipExpiration.expiration_date,
        zafa_expiration_date: shipExpiration.zafa_expiration_date
      }
    },
    async saveExpirationEdit () {
      await this.$api.expirationSave(this.dataToEdit)
        .then(() => {
          this.$api.expirations()
            .then((response) => {
              this.dialogEdit = false
            }).catch((error) => {
              if (error.response.status === 403) {
                this.$store.commit('token', null)
                this.$router.push('/login')
              }
            })
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$store.commit('token', null)
            this.$router.push('/login')
          }
        })
    },
    show_more (event) {
      document
        .getElementsByClassName('container-expiration')
        .forEach((element) => {
          if (event.target.closest('.container-expiration') !== element) {
            element.classList.remove('show_more_information')
          }
        })
      event.target
        .closest('.container-expiration')
        .classList.toggle('show_more_information')

      this.cancelExpirationEdit()
    },
    expirationCheck (dateString, limitDays) {
      if (dateString === null) return 0 // Si la fecha es nula, consideramos que no está expirada
      const now = new Date()
      const dateToCompare = new Date(dateString)

      const thirtyDaysFromNow = new Date(now)
      thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + limitDays)

      if (dateToCompare < now || dateToCompare <= thirtyDaysFromNow) {
        return 'Caducado'
      } else {
        return 'Vigente'
      }
    },
    getOlderDate (expirationsShip) {
      let date = null
      for (const key in expirationsShip) {
        if (expirationsShip.hasOwnProperty(key)) {
          const array = expirationsShip[key]
          // Itera sobre cada objeto dentro del array
          for (const item of array) {
            if (date === null || item.expiration_date < date) {
              date = item.expiration_date
            }
            if (
              item.zafa_expiration_date &&
              (date === null || item.zafa_expiration_date < date)
            ) {
              date = item.zafa_expiration_date
            }
          }
        }
      }
      return date
    },
    formatDate (date) {
      date = date.split('-')
      const formatDate = `${date[2]}/${date[1]}/${date[0].substr(2, 2)}`
      return formatDate
    }
  },
  mounted () {
    this.$api.expirations().catch((error) => {
      if (error.response.status === 403) {
        this.$store.commit('token', null)
        this.$router.push('/login')
      }
    })
    this.$api.ships().catch((error) => {
      if (error.response.status === 403) {
        this.$store.commit('token', null)
        this.$router.push('/login')
      }
    })
    this.show = true
    this.$store.commit('pageTitle', 'Caducidades')
    this.$store.commit('pageIcon', 'expirations.svg')
    this.$gtag.pageview(this.$route)
    this.expirations = this.expirationsApi
  }
}
</script>
