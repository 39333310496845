<template>
  <div v-if="ports_remote_control_buyer_purchases != undefined">
    <v-main id="page-compras-alias">
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-flex xs12 sm10>
            <v-container id="container-compras-alias">
              <div class="container" v-if="!refresh">
                <div
                  class="port"
                  v-for="(port_remotes_control, index) in ports_remote_control_buyer_purchases"
                  :key="index"
                >
                  <v-container class="port-container">
                    <v-row class="port-header" @click="portClick(index,port_remotes_control)">
                      <v-col cols="10">
                        <div :id="index">{{ port_remotes_control.port.alias }}</div>
                      </v-col>
                      <v-col cols="2">
                        <div class="portArrowContainer">
                          <img v-if="port_remotes_control.alias.length > 1" class="portArrow" src="../assets/i/icons/arrow_down.svg"
                            alt="arrow" v-bind:class="{ portArrowOpen: !port_remotes_control.show }" />
                        </div>

                      </v-col>
                    </v-row>
                    <transition name="slide-fade" v-if="port_remotes_control.alias">
                      <div class="alias-port-wrapper" v-if="port_remotes_control.show">
                        <div class="alias-port" v-for="(alias, index) in port_remotes_control.alias" :key="index">
                          <div class="alias" @click="goToCompras(port_remotes_control.port.id, alias.id)">
                            <h2>{{ alias.alias }}</h2>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </v-container>
                </div>

                <v-container class="no_ports" v-if="ports_remote_control_buyer_purchases.length <= 0">
                  No tienes Lonjas seleccionadas
                </v-container>
                </div>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>

</template>

<script>
export default {
  name: 'comprasAlias',
  components: {},
  data () {
    return {
      show: false,
      title: 'Mis puertos',
      timeout: null,
      refresh: false
    }
  },
  computed: {
    ports_remote_control_buyer_purchases () {
      return this.$store.state.ports_remote_control_buyer_purchases.sort(this.sortPortAlias)
    }
  },
  methods: {
    portClick (index, port) {
      if (port.alias.length <= 1) {
        this.goToCompras(port.port.id)
      } else {
        if (port.show) {
          port.show = false
        } else {
          this.ports_remote_control_buyer_purchases.forEach((element) => {
            element.show = false
          })
          port.show = true
        }
      }
      this.refresh = true
      this.refresh = false
    },
    goToCompras (portId, aliasId = 0) {
      this.$router.push({
        name: 'compras',
        params: {
          portId: portId,
          aliasId: aliasId
        }
      })
    },
    sortPortAlias (x, y) {
      return x.port.alias.localeCompare(y.port.alias)
    }
  },
  beforeMount () {
    if (!this.$store.state.ports_remote_control_buyer_purchases) {
      this.$api
        .ports_remote_control_buyer_purchases()
        .then(() => {
          if (this.ports_remote_control_buyer_purchases.length === 1) {
            this.ports_remote_control_buyer_purchases[0].show = true
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$store.commit('token', null)
            this.$router.push('/login')
          }
        })
    } else {
      if (this.ports_remote_control_buyer_purchases.length === 1) {
        this.ports_remote_control_buyer_purchases[0].show = true
      }
    }
  },
  mounted () {
    this.show = true
    this.$store.commit('pageTitle', 'Compras')
    this.$store.commit('pageIcon', 'sales.svg')
  }
}
</script>
