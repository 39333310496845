<template>
  <v-main v-if="show" class="page-ships">
    <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <v-flex class="page-ships-panel" xs12 sm10>
          <h3 class="page-ships-text">Selecciona un buque:</h3>
          <transition
            name="slideInUp"
            v-for="(ship, index) in this.ships"
            :key="index"
          >
            <v-card
              v-show="show"
              flat
              class="v-card-blue ship mb-4"
              @click.native="gotoShip(ship.id)"
            >
              <v-card-text>
                <span class="headline">{{ ship.name }}</span>
              </v-card-text>
            </v-card>
          </transition>

        </v-flex>

      </v-layout>
    </v-container>

    <v-alert id="alert_no_quota" :value="alertNoEffort" dark transition="scale-transition">No existen esfuerzos para ese barco</v-alert>
    <v-alert id="alert_no_quota" :value="alertForbidden" dark transition="scale-transition">No tienes permiso para acceder a este módulo</v-alert>

  </v-main>

</template>

<script>

export default {
  name: 'shipsEffort',
  props: {},
  components: {},
  data () {
    return {
      show: false,
      title: 'Mis barcos',
      alertNoEffort: false,
      alertForbidden: false,
      timeout: null
    }
  },
  computed: {
    gestefforts () {
      const index = this.$store.state.modules.map((e) => e.name).indexOf('app-esfuerzos')
      return index > -1
    },
    ships () {
      if (this.$store.state.ships.results !== undefined) {
        return this.$store.state.ships.results
      } else {
        return this.$store.state.ships
      }
    }
  },
  methods: {
    async gotoShip (id) {
      if (this.gestefforts) {
        // Search whether the selected ship has efforts assigned
        this.$api.check_efforts(id)
          .then((response) => {
            // console.log('CHECK ', response.data)
            if (response.data === true) {
              this.$api.efforts(id)
                .then((response) => {
                  // console.log('THEN ', response)
                  this.$router.push({
                    name: 'effort',
                    params: {
                      shipId: id
                    }
                  })
                })
                .catch((error) => {
                  if (error.response.status === '403') {
                    this.$store.commit('token', null)
                    this.$router.push('/login')
                  }
                })
            } else {
              this.$api.loading(false)
              if (this.alertNoEffort) {
                clearTimeout(this.timeout)
                this.alertNoEffort = !this.alertNoEffort
              }
              this.alertNoEffort = !this.alertNoEffort
              this.timeout = setTimeout(() => {
                this.alertNoEffort = !this.alertNoEffort
              }, 4000)
            }
          })
          .catch((error) => {
            if (error.response.status === '403') {
              this.$store.commit('token', null)
              this.$router.push('/login')
            }
          })
      } else {
        if (this.alertForbidden) {
          clearTimeout(this.timeout)
          this.alertForbidden = !this.alertForbidden
        }
        this.alertForbidden = !this.alertForbidden
        this.timeout = setTimeout(() => {
          this.alertForbidden = !this.alertForbidden
        }, 4000)
      }
    },
    sortShips (x, y) {
      return x.name.localeCompare(y.name)
    }
  },
  beforeMount () {
    this.$api.ships()
      .then(() => {
        if (this.ships.length === 1) {
          this.gotoShip(this.ships[0].id)
        } else if (this.ships.length === 0) {
          this.$router.back()
        }
      })
      .catch((error) => {
        if (error.response.status === '403') {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
  },
  mounted () {
    this.show = true
    this.$store.commit('pageTitle', 'Esfuerzos')
    this.$store.commit('pageIcon', 'efforts.svg')
  }
}
</script>
