<template>

    <v-layout class="effort-detail">
        <v-flex xs12>
            <label class="card-text">
                {{ gest_effort.fishing_type }}
            </label>
            <div v-if="assigned > 0" class="effort-graph">
                <v-progress-circular
                    :size="130"
                    :width="20"
                    :rotate="-90"
                    :color="circularColor(gest_effort.consumed_days, assigned)"
                    :value="percentage(gest_effort.consumed_days, assigned)"
                >
                    <span class="label">
                        {{ percentage(gest_effort.consumed_days, assigned) }}%
                    </span>
                </v-progress-circular>

                <label class="label" :class="labelType(gest_effort.consumed_days, assigned)">
                    {{ consumed }} / {{ assigned }} días
                </label>
            </div>
            <div v-else class="error-graph">
                <label class="label ef-error">
                    Este barco no tiene esfuerzo asignado
                </label>
                <label class="label ef-info">
                    {{ consumed }} días consumidos
                </label>
            </div>
            <label class="label">
                Última actualización: {{ gest_effort.last_update }}
            </label>
        </v-flex>
    </v-layout>
</template>

<script>

export default {
  props: {
    gest_effort: Object,
    assigned_ef: String
  },
  computed: {
    consumed () {
      let n = Number(this.gest_effort.consumed_days)
      if (n - Math.trunc(n) > 0) {
        return n.toFixed(1)
      } else {
        return n.toFixed(0)
      }
    },
    assigned () {
      return Number(this.assigned_ef)
    }
  },
  methods: {
    percentage (consumed, assigned) {
      return ((consumed * 100) / assigned).toFixed(1)
    },
    circularColor (consumed, assigned) {
      let perc = this.percentage(consumed, assigned)
      if (perc > 95) {
        return '#FF3838'
      } else if (perc >= 80) {
        return '#FFB238'
      } else {
        return '#00B5E2'
      }
    },
    labelType (consumed, assigned) {
      let perc = this.percentage(consumed, assigned)
      if (perc > 95) {
        return 'ef-error'
      } else if (perc >= 80) {
        return 'ef-warning'
      } else {
        return 'ef-info'
      }
    }
  }

}
</script>
