<template>
  <div>
    <loading-component></loading-component>
    <v-main class="page-ventas">
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-flex xs12 sm10>
            <v-container class="container-ventas">
              <v-tabs v-model="currentTab">
                <v-tab class="tab-diario" :href="'#tab-diario'">DIARIO</v-tab>
                <v-tab class="tab-semanal" :href="'#tab-semanal'"
                  >SEMANAL</v-tab
                >
              </v-tabs>
              <v-tabs-items v-model="currentTab">
                <v-tab-item :value="'tab-diario'">
                  <v-card flat height="75vh">
                    <v-card-text>
                      <div class="botones-fechas">
                        <button v-on:click="load_auctions_rows('diario', false)">
                          <img
                            src="../assets/i/icons/arrow_down.svg"
                            class="icon-row-left"
                          />
                        </button>
                        <v-menu
                          v-model="menuDateDiario"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              {{ dateStringDiarioCompanion }}
                              {{ dateStringDiario | formatDate }}
                            </span>
                          </template>
                          <v-date-picker
                            v-model="dateStringDiario"
                            no-title
                            @input="menuDateDiario = false"
                            locale="es-es"
                            :max="today"
                            full-width
                          ></v-date-picker>
                        </v-menu>
                        <button
                          v-if="dateStringDiario < today"
                          v-on:click="load_auctions_rows('diario', true)"
                        >
                          <img
                            src="../assets/i/icons/arrow_down.svg"
                            class="icon-row-right"
                          />
                        </button>
                      </div>
                      <div v-if="loadingAuctionsDay" class="loading-auctions">
                        <v-progress-circular
                          :size="200"
                          :width="10"
                          color="white"
                          indeterminate
                        >
                          Cargando...
                        </v-progress-circular>
                      </div>
                      <div v-else>
                        <table
                          v-if="auctions_day.length > 0"
                          class="table-auctions table-auctions-diario"
                        >
                          <tr>
                            <th class="col-header-name"></th>
                            <th class="col-header-amount">Kgs</th>
                            <th class="col-header-average-price">Precio</th>
                            <th class="col-header-price">Importe</th>
                          </tr>
                          <tr>
                            <td colspan="4" class="td-aux">
                              <div class="table-auctions-diario-container">
                                <div  v-for="(
                                    auction, index_auction
                                  ) in auctions_day"
                                  :key="index_auction">
                                  <tr
                                    class="table-row"
                                    @click="showBatchDetails(index_auction)"
                                  >
                                    <td class="col-name">
                                      <span class="col-name-fao">
                                        {{ auction.specie_fao }}
                                      </span>
                                      <div class="col-name-name">
                                        {{ auction.specie_name }}
                                      </div>
                                    </td>
                                    <td class="col-amount">
                                      {{ auction.amount | formatSecondDecimal }}
                                    </td>
                                    <td class="col-average-price">
                                      {{ (auction.price  / auction.amount) | formatSecondDecimal}}
                                    </td>
                                    <td class="col-price">
                                      {{ auction.price | formatSecondDecimal }}
                                    </td>
                                  </tr>
                                  <div v-if="activeBatchControl[index_auction] && moduleDetails" :class="moduleDetails ? 'batch-details' : ''">

                                    <tr v-for="(batch, batchIndex) in auction.batch_details" :key="batchIndex">
                                      <td class="col-name">
                                        {{ batch.batch_number }} ({{ batch.specie_caliber }})
                                      </td>
                                      <td class="col-amount">
                                        {{ batch.amount |formatSecondDecimal }}
                                      </td>
                                      <td class="col-average-price">
                                        {{ batch.price | formatSecondDecimal }}
                                      </td>
                                      <td class="col-price">
                                        {{ (batch.price * batch.amount) | formatSecondDecimal }}
                                      </td>
                                    </tr>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                        <div v-else class="no-auctions">No hay datos</div>
                        <div
                          v-if="auctions_day.length > 0"
                          class="total-auctions"
                        >
                          <table>
                            <tr>
                              <th colspan="2">TOTAL VENTAS</th>
                            </tr>
                            <tr>
                              <td>
                                Cantidad <br />
                                {{
                                  auctions_day_total.amount
                                    | formatSecondDecimal
                                }}
                                Kg
                              </td>
                              <td>
                                Importe <br />
                                {{
                                  auctions_day_total.price | formatSecondDecimal
                                }}
                                €
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-semanal'">
                  <v-card flat height="75vh">
                    <v-card-text>
                      <div class="botones-fechas">
                        <button v-on:click="load_auctions_rows('semanal', false)">
                          <img
                            src="../assets/i/icons/arrow_down.svg"
                            class="icon-row-left"
                          />
                        </button>
                        <v-menu
                          v-model="menuDateSemanal"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              Semana {{ dateStringSemanal | formatDate }}
                            </span>
                          </template>
                          <v-date-picker
                            v-model="dateStringSemanal"
                            no-title
                            @input="menuDateSemanal = false"
                            locale="es-es"
                            :max="today"
                            full-width
                          ></v-date-picker>
                        </v-menu>
                        <button
                          v-if="dateStringSemanal < todayMonday"
                          v-on:click="load_auctions_rows('semanal', true)"
                        >
                          <img
                            src="../assets/i/icons/arrow_down.svg"
                            class="icon-row-right"
                          />
                        </button>
                      </div>
                      <div v-if="loadingAuctionsWeek" class="loading-auctions">
                        <v-progress-circular
                          :size="200"
                          :width="10"
                          color="white"
                          indeterminate
                        >
                          Cargando...
                        </v-progress-circular>
                      </div>
                      <div v-else>
                        <table
                          v-if="auctions_week.length > 0"
                          class="table-auctions"
                        >
                          <tr>
                            <th class="col-name col-header" colspan="2"></th>
                            <th class="col-amount col-header">Kgs</th>
                            <th class="col-price col-header">Importe</th>
                          </tr>
                          <tr
                            v-for="(auction, index_auction) in auctions_week"
                            :key="index_auction"
                            class="table-row"
                          >
                            <td class="col-name" colspan="2">
                              {{ weekDayFullName(auction.sale_date) }}
                            </td>
                            <td class="col-amount">
                              {{ auction.amount | formatSecondDecimal }}
                            </td>
                            <td class="col-price">
                              {{ auction.price | formatSecondDecimal }}
                            </td>
                          </tr>
                        </table>
                        <div v-else class="no-auctions">No hay datos</div>
                        <div
                          v-if="auctions_week.length > 0"
                          class="total-auctions"
                        >
                          <table>
                            <tr>
                              <th colspan="2">TOTAL VENTAS</th>
                            </tr>
                            <tr>
                              <td>
                                Cantidad <br />
                                {{
                                  auctions_week_total.amount
                                    | formatSecondDecimal
                                }}
                                Kg
                              </td>
                              <td>
                                Importe <br />
                                {{
                                  auctions_week_total.price
                                    | formatSecondDecimal
                                }}
                                €
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import LoadingComponent from '../components/LoadingComponent.vue'

export default {
  name: 'ventas',
  components: {
    LoadingComponent
  },
  data () {
    return {
      show: false,
      title: 'Mis ventas',
      dateSemanal: new Date(),
      dateStringSemanal: null,
      menuDateSemanal: false,
      dateDiario: new Date(),
      dateStringDiario: null,
      dateStringDiarioCompanion: '',
      menuDateDiario: false,
      arrowControl: true,
      currentTab: 'tab-diario',
      loadingAuctionsDay: false,
      loadingAuctionsWeek: false,
      timerday: null,
      timerweek: null,
      activeBatchControl: []
    }
  },
  props: {
    shipId: {
      type: [Number, String],
      required: true
    }
  },
  watch: {
    dateStringSemanal () {
      this.load_auctionsWeek()
    },
    dateStringDiario () {
      this.load_auctionsDay()
    }
  },
  computed: {
    today () {
      return this.format_date(new Date())
    },
    todayMonday () {
      return this.format_date(this.getMonday(new Date()))
    },
    yesterday () {
      return this.format_date(new Date(new Date().setDate(new Date().getDate() - 1)))
    },
    auctions_week () {
      let auctionsF = []
      let auctionF = null
      this.$store.state.auctions_week.forEach(function (auction) {
        auctionF = auctionsF.find(
          (x) => x.sale_date == auction.specie_sale_date
        )
        if (auctionF != undefined) {
          auctionF.amount += auction.amount
          auctionF.price += auction.price * auction.amount
        } else {
          auctionsF.push({
            sale_date: auction.specie_sale_date,
            amount: auction.amount,
            price: auction.price * auction.amount,
            coin_code: auction.coin_code
          })
        }
      })
      return auctionsF.sort(this.sortAuctionsDate)
    },
    auctions_week_total () {
      let auctionsT = {
        amount: 0,
        price: 0
      }
      this.auctions_week.forEach(function (auction) {
        auctionsT.amount += auction.amount
        auctionsT.price += auction.price
      })
      return auctionsT
    },
    auctions_day () {
      let auctionsF = []
      let auctionF = null
      this.$store.state.auctions_day.forEach(function (auction) {
        auctionF = auctionsF.find((x) => x.specie_id == auction.specie.id)
        if (auctionF != undefined) {
          auctionF.amount += auction.amount
          auctionF.price += auction.price * auction.amount
        } else {
          auctionsF.push({
            specie_id: auction.specie.id,
            specie_fao: auction.specie.fao_code,
            specie_name: auction.specie.association_name,
            amount: auction.amount,
            price: auction.price * auction.amount,
            coin_code: auction.coin_code,
            batch_details: []
          })
        }
      })
      this.$store.state.auctions_day.forEach(auction => {
        auctionsF.forEach(auctionFormmated => {
          if (auction.specie.fao_code === auctionFormmated.specie_fao) {
            auctionFormmated.batch_details.push({
              batch_number: auction.specie_batch,
              specie_caliber: auction.specie_caliber_code,
              amount: auction.amount,
              price: auction.price
            })
          }
        })
      })
      parent = this
      if (this.activeBatchControl.length !== auctionsF.length) {
        auctionsF.forEach(() => {
          parent.activeBatchControl.push(false)
        })
      }
      return auctionsF
        .sort(this.sortAuctionsName)
        .sort(this.sortAuctionsPrice)
        .reverse()
    },
    auctions_day_total () {
      let auctionsT = {
        amount: 0,
        price: 0
      }
      this.auctions_day.forEach(function (auction) {
        auctionsT.amount += auction.amount
        auctionsT.price += auction.price
      })
      return auctionsT
    },
    moduleDetails () {
      return this.$store.state.modules.map(m => m.name).includes('app-ventas-detalles-lotes')
    }
  },
  methods: {
    PadLeft (value, length) {
      return value.toString().length < length
        ? this.PadLeft('0' + value, length)
        : value
    },
    format_date (date) {
      return (
        this.PadLeft(date.getFullYear(), 2) +
        '-' +
        this.PadLeft(date.getMonth() + 1, 2) +
        '-' +
        this.PadLeft(date.getDate(), 2)
      )
    },
    getMonday (d) {
      d = new Date(d)
      var day = d.getDay()
      var diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
      return new Date(d.setDate(diff))
    },
    load_auctions_rows (type, positive) {
      if (this.arrowControl) {
        this.arrowControl = false
        let weekNum = (positive ? 7 : -7)
        let dayNum = (positive ? 1 : -1)
        switch (type) {
          case 'semanal':
            this.dateSemanal = this.getMonday(this.dateSemanal)
            this.dateSemanal.setDate(this.dateSemanal.getDate() + weekNum)
            this.dateStringSemanal = this.format_date(this.dateSemanal)
            break
          case 'diario':
            this.dateDiario.setDate(this.dateDiario.getDate() + dayNum)
            this.dateStringDiario = this.format_date(this.dateDiario)
            break
          default:
            break
        }
        setTimeout(() => {
          this.arrowControl = true
        }, 100)
      }
    },
    sortAuctionsPrice (x, y) {
      return x.price - y.price
    },
    sortAuctionsName (x, y) {
      return x.specie_name.localeCompare(y.specie_name)
    },
    sortAuctionsDate (x, y) {
      return x.sale_date.localeCompare(y.sale_date)
    },
    weekDayFullName (date) {
      let currentDate = new Date(date)
      let dayName = ''
      switch (currentDate.getDay()) {
        case 1:
          dayName = 'Lunes'
          break
        case 2:
          dayName = 'Martes'
          break
        case 3:
          dayName = 'Miércoles'
          break
        case 4:
          dayName = 'Jueves'
          break
        case 5:
          dayName = 'Viernes'
          break
        case 6:
          dayName = 'Sábado'
          break
        case 7:
          dayName = 'Domingo'
          break
        default:
          dayName = 'None'
          break
      }
      return dayName
    },
    load_auctionsWeek () {
      this.loadingAuctionsWeek = true
      this.dateSemanal = new Date(this.dateStringSemanal)
      this.dateSemanal = this.getMonday(this.dateSemanal)
      this.dateStringSemanal = this.format_date(this.dateSemanal)
      let dateStringSemanalControl = this.dateStringSemanal
      setTimeout(() => {
        if (dateStringSemanalControl === this.dateStringSemanal) {
          this.$api
            .auctions_week(this.dateStringSemanal, this.shipId, 'v')
            .then((response) => {
              this.loadingAuctionsWeek = false
              clearInterval(this.timerweek)
              if (this.format_date(this.dateSemanal) == this.todayMonday) {
                this.timerweek = setInterval(this.load_auctionsWeek, 30000)
              }
            })
            .catch((error) => {
              if (error.response.status === 403) {
                this.$store.commit('token', null)
                this.$router.push('/login')
              }
            })
        }
      }, 500)
    },
    load_auctionsDay () {
      this.loadingAuctionsDay = true
      this.dateDiario = new Date(this.dateStringDiario)
      this.dateStringDiario = this.format_date(this.dateDiario)
      let dateStringDiarioControl = this.dateStringDiario
      if (this.format_date(this.dateDiario) == this.today) {
        this.dateStringDiarioCompanion = 'Hoy'
      } else if (
        this.format_date(this.dateDiario) == this.yesterday
      ) {
        this.dateStringDiarioCompanion = 'Ayer'
      } else {
        this.dateStringDiarioCompanion = 'Día'
      }
      setTimeout(() => {
        if (dateStringDiarioControl === this.dateStringDiario) {
          this.$api
            .auctions_day(this.dateStringDiario, this.shipId, 'v')
            .then((response) => {
              this.loadingAuctionsDay = false
              clearInterval(this.timerday)
              if (
                this.format_date(this.dateDiario) == this.today
              ) {
                this.timerday = setInterval(this.load_auctionsDay, 30000)
              }
            })
            .catch((error) => {
              if (error.response.status === 403) {
                this.$store.commit('token', null)
                this.$router.push('/login')
              }
            })
        }
      }, 500)
    },
    showBatchDetails (index) {
      if (this.moduleDetails) {
        let currentSpecie = this.activeBatchControl[index]
        parent = this
        this.activeBatchControl.forEach((value, aIndex, array) => {
          parent.$set(parent.activeBatchControl, aIndex, false)
        })
        this.$set(this.activeBatchControl, index, (!currentSpecie))
      }
    }
  },
  beforeMount () {
    this.dateSemanal = this.getMonday(this.dateSemanal)
    this.dateStringSemanal = this.format_date(this.dateSemanal)
    this.dateStringDiario = this.format_date(this.dateDiario)
  },
  mounted () {
    this.show = true
    this.$store.commit('pageTitle', 'Ventas')
    this.$store.commit('pageIcon', 'sales.svg')
    this.$gtag.pageview(this.$route)
    document.addEventListener('visibilitychange', this.load_auctionsDay, true)
    document.addEventListener('visibilitychange', this.load_auctionsWeek, true)
  },
  destroyed () {
    clearInterval(this.timerday)
    clearInterval(this.timerweek)
    document.removeEventListener(
      'visibilitychange',
      this.load_auctionsDay,
      true
    )
    document.removeEventListener(
      'visibilitychange',
      this.load_auctionsWeek,
      true
    )
  }
}
</script>
