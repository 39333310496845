<template>
  <div class="captures-view">
    <div class="year-title"> {{ currentYear }}</div>
    <div
      class="captures-view-capture"
      v-for="(capture, index) in captures"
      :key="index"
    >
      <div class="captures-view-capture-name">
        {{ capture.specie_name }}
      </div>
      <div class="captures-view-capture-total">
        {{ capture.total | formatNumberRounded }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'captures-view',
  data () {
    return {
      show: false,
      title: 'Capturas',
      currentYear: new Date().getFullYear()
    }
  },
  computed: {
    captures () {
      var captures = []
      var otherCaptures = {
        specie_name: 'Otros',
        total: 0
      }
      let i = 0
      let flagHaveOtherCaptures = false
      this.$store.state.captures.forEach((capture) => {
        if (i < 20) {
          captures.push(capture)
        } else {
          flagHaveOtherCaptures = true
          otherCaptures.total += parseInt(capture.total)
        }
        i++
      })

      if (flagHaveOtherCaptures) {
        captures.push(otherCaptures)
      }

      return captures
    }
  },
  methods: {},
  mounted () {
    if (
      new Date().getTime() - this.$store.state.lastCapturesUpdate >
      process.env.VUE_APP_CAPTURES_CACHE_TIME * 1000
    ) {
      this.$api
        .captures()
        .then((response) => {
          this.$store.commit('lastCapturesUpdate', new Date().getTime())
        })
        .catch((error) => {
          if (['403', '404'].indexOf(error.response.status) > -1) {
            this.$store.commit('token', null)
            this.$router.push('/login')
          }
        })
    }
    this.show = true
    this.$store.commit('pageTitle', 'Capturas')
    this.$store.commit('pageIcon', 'capturas.svg')
  }
}
</script>
