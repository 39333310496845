import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    association: '',
    pageTitle: '',
    pageIcon: '',
    phone: null,
    token: null,
    loading: false,
    ships: {},
    sizes: {},
    species: {},
    seatides: {},
    quotas: {},
    gestcuotas: {},
    gestcuota_all_years: {},
    quotas_sales: {},
    quota_all_years: {},
    efforts: {},
    gestefforts_ship: {},
    captures: [],
    avisos: {},
    aviso: {},
    modules: [],
    hidden_modules: [],
    modal: {
      state: false,
      title: '',
      text: ''
    },
    welcome: true,
    lastNotificationsUpdate: 0,
    lastQuotasUpdate: 0,
    lastCapturesUpdate: 0,
    drawer: false,
    expirations: {},
    entity_type: '',
    auctions_week: [],
    auctions_day: [],
    global_auctions_stats: [],
    global_auctions_stats_main_port: 0,
    global_auctions_stats_ports: [],
    ports_remote_control_buyer_purchases: [],
    idStatistics: null
  },
  mutations: {
    association (state, payload) {
      state.association = payload
    },
    globalModal (state, payload) {
      state.modal = payload
    },
    phone (state, payload) {
      state.phone = payload
    },
    token (state, payload) {
      state.token = payload
      if (window.FlutterToken) {
        window.FlutterToken.postMessage(payload)
      }
    },
    loading (state, payload) {
      state.loading = payload
    },
    ships (state, payload) {
      state.ships = payload
    },
    quotas (state, payload) {
      state.quotas = payload.quotas
    },
    gestcuotas (state, payload) {
      state.gestcuotas = payload.results
    },
    gestcuota_all_years (state, payload) {
      state.gestcuota_all_years = payload
    },
    quotas_sales (state, payload) {
      state.quotas_sales = payload
    },
    quota_all_years (state, payload) {
      state.quota_all_years = payload
    },
    efforts (state, payload) {
      state.efforts = payload
    },
    gestefforts_ship (state, payload) {
      state.gestefforts_ship = payload
    },
    sizes (state, payload) {
      state.sizes = payload
    },
    species (state, payload) {
      state.species = payload
    },
    seatides (state, payload) {
      state.seatides = payload
    },
    avisos (state, payload) {
      state.avisos = payload
    },
    aviso (state, payload) {
      state.aviso = payload
    },
    captures (state, payload) {
      state.captures = payload
    },
    speciesCaptures (state, payload) {
      state.speciesCaptures = payload
    },
    capturesMap (state, payload) {
      state.capturesMap = payload
    },
    modules (state, payload) {
      state.modules = payload.modules
    },
    hidden_modules (state, payload) {
      state.hidden_modules = payload.hidden_modules
    },
    pageTitle (state, payload) {
      state.pageTitle = payload
    },
    pageIcon (state, payload) {
      state.pageIcon = payload
    },
    lastNotificationsUpdate (state, payload) {
      state.lastNotificationsUpdate = payload
    },
    lastQuotasUpdate (state, payload) {
      state.lastQuotasUpdate = payload
    },
    lastCapturesUpdate (state, payload) {
      state.lastCapturesUpdate = payload
    },
    welcome (state, payload) {
      state.welcome = payload
    },
    drawer (state, payload) {
      state.drawer = payload
    },
    expirations (state, payload) {
      state.expirations = payload
    },
    entity_type (state, payload) {
      state.entity_type = payload.entity_type
    },
    auctions_week (state, payload) {
      state.auctions_week = payload.auctions
    },
    auctions_day (state, payload) {
      state.auctions_day = payload.auctions
    },
    global_auctions_stats (state, payload) {
      state.global_auctions_stats = payload.auctions
    },
    global_auctions_stats_ports (state, payload) {
      state.global_auctions_stats_main_port = payload.main_port
      state.global_auctions_stats_ports = payload.ports
    },
    ports_remote_control_buyer_purchases (state, payload) {
      state.ports_remote_control_buyer_purchases = payload
    },
    forbiddenZones (state, payload) {
      state.forbiddenZones = payload
    }
  },
  actions: {
    showGlobalModal: ({ commit }, payload) => commit('globalModal', Object.assign(payload, { status: true })),
    hideGlobalModal: ({ commit }, payload) => commit('globalModal', { status: false })
  },
  getters: {
    authenticated: state => {
      return state.token != null
    }
  },
  plugins: [createPersistedState({
    key: process.env.VUE_APP_VUEX_KEY
  })]
})
